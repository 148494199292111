import makeApiCall from "../MakeApiCall";

const InviteMember = async (payload) => {
  return makeApiCall("POST", `/api/team/addTeam`, true, "", payload);
};

const DeleteMember = async (payload) => {
  return makeApiCall("DELETE", `/api/team/${payload?.id}`, true, "", payload);
};

const EditMember = async (payload) => {
  return makeApiCall("PATCH", `/api/team/${payload?.id}`, true, "", payload);
};

const inviteMemberService = {
  InviteMember,
  DeleteMember,
  EditMember,
};

export default inviteMemberService;
