import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { ROUTES } from "../../routes/RouterConfig";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
const StoreOwner = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    hobbies: "",
    mobile: "",
    dOB: "",
    email: "",
    role: "",
    skills: "",
    education: "",
    workExperience: "",
  });

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleFileChange = (e) => {
    setProfile(e.target.files[0]);
    console.log("file", profile);
  };

  const handleSubmit = async () => {
    try {
    } catch (error) {
      console.log("err", error);
    }
  };

  const changeDir = (dir) => {
    navigate(dir);
  };

  return (
    <div className="Login">
      <Header />

      <div className="py-16">
        <div className="lg:w-8/12 md:w-8/12 sm:w-8/12 w-full m-auto ">
          <h2 className="font-semibold text-4xl  text-center pb-8">
            Freelancer Details
          </h2>
          <Form>
            <Container fluid="sm">
              <Row>
                <Col sm="12">
                  <Row className="my-3">
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Upload File
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          First Name
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter First Name"
                          // {...getFieldProps("useremail")}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Email ID
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="email"
                          placeholder="Enter Your Email ID"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Education
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Education"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">Role</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Role"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Mobile Number
                        </Form.Label>

                        <Form.Control
                          className="rounded-md h-10 mr-3"
                          type="number"
                          placeholder="Enter Your Number"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Last Name"
                        />
                      </Form.Group>

                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">DOB</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="date"
                          placeholder="Enter Your Email ID"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Skills
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Skill"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Hobbies
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Hobbies"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col sm="12">
                      <h2 className="my-3 font-semibold text-xl">
                        Work Experience
                      </h2>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Company Name
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Company Name"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">From</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="date"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Responsiblities
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Responsiblities"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">Role</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Role"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">To</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="text-center">
                    <Button
                      className="bg-[#0d6efd] rounded-full w-32 mt-12"
                      variant="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StoreOwner;
