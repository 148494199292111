import axios from "axios";
import Config from "../../Config/config";
// import {getObjectData} from '../../services/Storage/Storage';

const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const formDataHeader = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
};

// const getOptions = (method, payloadType, payload) => {
//   let options = {
//     method: method,
//     headers: payloadType === 'formData' ? formDataHeader : header,
//   };
//   if ((!['GET'], ['DELETE'].includes(method))) {
//     options = {
//       ...options,
//       maxBodyLength: Infinity,
//       maxContentLength: Infinity,
//       data: payload,
//       // ...(payload && {data: payload}),
//     };
//   }
//   ;
//   return options;
// };

// i need to write again
const getOptions = (method, payloadType, payload) => {
  // Define default headers
  let headers = payloadType === "formData" ? formDataHeader : header;

  // Define options object with method and headers
  let options = {
    method: method,
    headers: headers,
  };

  // Check if the method is not 'GET' or 'DELETE'
  if (method !== "GET" && method !== "DELETE") {
    // Extend options with additional properties for non-GET and non-DELETE requests
    options = {
      ...options, // Spread the existing options
      maxBodyLength: Infinity, // Set maxBodyLength to Infinity
      maxContentLength: Infinity, // Set maxContentLength to Infinity
      data: payload, // Set data property to payload
    };
  }
  ;
  // Return the options object
  return options;
};
// const generateApiCallFunction = (withToken, URL, options) => {
//   return async () => {
//     try {
//       if (withToken) {
//         let tokenData = JSON.parse(localStorage.getItem("authDetails"));
//         options.headers["Authorization"] = `Bearer ${tokenData.accessToken}`;
//       }
//       delete options.url;
//       const response = await axios(URL, options);
//       ;
//       return response;
//     } catch (error) {
//       ;
//       return error.response;
//     }
//   };
// };
const generateApiCallFunction = (withToken, URL, options) => {
  return async () => {
    try {
      if (withToken) {
        let tokenData = JSON.parse(localStorage.getItem("authDetails"));
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${tokenData.accessToken}`,
        };
      }
      ;
      delete options.url;
      const response = await axios(URL, options);
      return response;
    } catch (error) {
      return error.response;
    }
  };
};

const ApiCallHOF = (method, url, withToken, payloadType, payload) => {
  const URL = Config.apiBaseUrl + url;
  const options = getOptions(method, payloadType, payload);
  const ApiCallFunction = generateApiCallFunction(withToken, URL, options);
  return ApiCallFunction;
};

const ApiCalls = {
  ApiCallHOF,
};

export default ApiCalls;
