import makeApiCall from "../MakeApiCall";

const getBlogs = async () => {
  return makeApiCall("GET", `/api/cms/all`, true, "", null);
};

const getBlogBySlug = async (slug) => {
  return makeApiCall("GET", `/api/cms/${slug}`, true, "", null);
};

const blogServices = {
  getBlogs,
  getBlogBySlug,
};

export default blogServices;
