// import TeamsCms from "../pages/CMS/TeamsCms";

// import My_Article from "../components/My_Article";

export const ROUTES = {
  Login: "/login",
  Register: "/register",
  Home: "/",
  Profile: "/profile",
  Hiring: "/start-hiring",
  Requirement: "/requirement",
  Freelancer: "/freelancer",
  My_Article: "/my-article",
  SubscriptionPayment: "/payment",
  Welcome: "/welcome",
  Landing_Page1: "/teams",
  HealthTech: "/healthtech",
  HealthTechHiring: "/tech-hiring",
  Connect: "/connect",

  // Article: "/article/:articleslug",
  TeamsCms: "/teams-cms",
  Page: "/page/:pageslug",
  Visitors: "/visitors",
  Slug: "/:slug",
  Slugs: "/slugs",
  NotFound: "*",
};
