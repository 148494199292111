import React, { useState, useRef, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Banner2 from "../../components/Banner2";
import Trusted from "../../components/Trusted";
import Calculated from "../../components/Calculated";
import Video from "../../components/Video";
import Hire from "../../components/Hire";
import Pricing from "../../components/Pricing";
import Assemble from "../../components/Assemble";
import Faq from "../../components/Faq";
import Build from "../../components/Build";
import dotted from "../../assets/images/curved-dotted-line.svg";
import groupImg from "../../assets/images/group-of-people.png";
import { ROUTES } from "../../routes/RouterConfig";
import { useNavigate } from "react-router-dom";
import VideoTech from "../../components/VideoTech";
import TechPricing from "../../components/TechPricing";
import TechTrusted from "../../components/TechTrusted";
// import My_Article from "../../components/My_Article";

const Assembles = [
  {
    name: "Bilal Sununu",
    designation: "Business Development Director",
    img: require("../../assets/images/bilal.jpg"),
    content:
      "“Their team model is a great way to save costs in hiring experts for your product development”",
  },
  {
    name: "Anna Hyatt",
    designation: "Director",
    img: require("../../assets/images/anna.jpg"),
    content:
      "“I was eagerly looking for a team to support us with our product development and we finally found Sofyrus. I will want Sofyrus to be a part of my team every time.”",
  },
  {
    name: "Marc Powell",
    designation: "Chief Operating Officer",
    img: require("../../assets/images/marc.jpg"),
    content:
      "“Sofyrus team has done a great job to make our project a success. I highly recommend them for your next project.”",
  },
  {
    name: "Carey Buttfield",
    designation: "Chief Executive Officer",
    img: require("../../assets/images/carey.webp"),
    content:
      "“I am very pleased with the work Sofyrus has done and would recommend them for your next not-so-ordinary website project if you want it done right.”",
  },
  {
    name: "Victor ",
    designation: "Chief Executive Officer",
    img: require("../../assets/images/victor.png"),
    content:
      "“Sofyrus understands our needs, and they’re just a perfect team for our business.”",
  },
  {
    name: "Ali Yaqub",
    designation: "Founder",
    img: require("../../assets/images/ali.jpg"),
    content:
      "“They are honest hardworking and overachievers. Loved the way they helped us design our SaaS product.”",
  },
];
const Builds = [
  {
    name: "Front End Developer",

    img: require("../../assets/images/coding.png"),
  },
  {
    name: "Product Manager",

    img: require("../../assets/images/manager.png"),
  },
  {
    name: "Product Designer",

    img: require("../../assets/images/vector-design.png"),
  },
  {
    name: "Data Scientist",

    img: require("../../assets/images/data-science.png"),
  },
  // {
  //   name: "Growth Marketer",

  //   img: require("../../assets/images/growth.png"),
  // },
  {
    name: "AI Expert",

    img: require("../../assets/images/microchip.png"),
  },
  {
    name: "Back End Developer",

    img: require("../../assets/images/backend.png"),
  },
];

const Industry = [
  {
    name: "HealthTech & HealthCare",
    img: require("../../assets/images/healthcare.png"),
  },
  {
    name: "EdTech",
    img: require("../../assets/images/education.png"),
  },
  {
    name: "Construction",

    img: require("../../assets/images/construction.png"),
  },
  {
    name: "Online Services",

    img: require("../../assets/images/online_services.png"),
  },
  {
    name: "Retail Transportation",
    img: require("../../assets/images/transportation.png"),
  },
  {
    name: "RPA",
    img: require("../../assets/images/robot.png"),
  },
  {
    name: "Energy and Utilities",

    img: require("../../assets/images/utilities.png"),
  },
  {
    name: "Immigration",

    img: require("../../assets/images/passport.png"),
  },
];

// Build Product
const Product = [
  {
    name: "Mobile App",
    img: require("../../assets/images/mobile-application.png"),
  },
  {
    name: "Web App",
    img: require("../../assets/images/webaap.png"),
  },
  {
    name: "Data Science",
    img: require("../../assets/images/data-engineer.png"),
  },
  {
    name: "Gen AI Feature",
    img: require("../../assets/images/artificial-intelligence.png"),
  },
  {
    name: "Rapid Prototype",
    img: require("../../assets/images/web-design.png"),
  },
  {
    name: "Marketing Website",
    img: require("../../assets/images/goal.png"),
  },
  {
    name: "Technology Migration",
    img: require("../../assets/images/migration.png"),
  },
  {
    name: "System Architecture",
    img: require("../../assets/images/internet (1).png"),
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const scrollToProduct = () => {
  const productTabPanel = document.getElementById("productTabPanel");
  if (productTabPanel) {
    productTabPanel.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }
};

const HealthTech = () => {
  document.title = "HealthTech Team";
  const navigate = useNavigate();
  //   const changeDir = (dir) => {
  //     navigate(dir);
  //   };
  const [value, setValue] = React.useState(0);
  const [selectedBuildIndex, setSelectedBuildIndex] = useState(null);
  const containerRef = useRef(null);
  const [tabValue, setTabValue] = useState([]);

  const handleCheckboxClick = (index) => {
    setSelectedBuildIndex(index === selectedBuildIndex ? null : index);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // Calculate scrollLeft position based on the selected tab index
    const tabWidth = containerRef.current.clientWidth;
    const scrollLeft = tabWidth * newValue;

    // Smooth scroll animation from current scroll position to the new scroll position
    containerRef.current.scrollTo({
      left: scrollLeft,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (value === 0) setTabValue(Builds);
    else setTabValue(Product);
  }, [value]);

  return (
    <div id="NewHome">
      <Header />
      <Banner2 />

      <section
        className="py-10 px-3 lg:px-1  bg-white sm:py-16 lg:py-24"
        id="FindTeam"
      >
        <div className="w-full max-w-xl xl:w-full text-center lg:w-full md:mx-auto sm:mx-auto  lg:mx-auto xl:mx-auto   xl:max-w-2xl">
          <h3 className="lg:text-[32px] lg:leading-[40px]  text-2xl font-bold text-black mb-3">
            Enhance Patient Care with a Dynamic Team Fueled by HealthTech
            Expertise
          </h3>
          <p className="text-[15px] font-normal text-black ">
            Get on-demand access to Full Stack engineers, product managers,
            designers and many more — with the extensive healthTech product
            experience
          </p>
        </div>
        <Box sx={{ width: "100%" }}>
          <div className="flex justify-center items-center">
            <Box
              // className="lg:max-w-[22.3%]  md:max-w-[35.3%] sm:max-w-[38%]  max-w-[80%]  sm:w-full mx-auto mt-5 rounded-full tabs-ui"
              className="mx-auto mt-5 rounded-full tabs-ui"
              sx={{
                borderColor: "divider",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="justify-center items-center"
              >
                <Tab
                  className="justify-center items-center"
                  sx={{
                    fontWeight: "700",
                    fontSize: "13px",
                    lg: { fontSize: "14px" },
                    xl: { fontSize: "16px" },
                  }}
                  label="Build a Team"
                  {...a11yProps(0)}
                />
                <Tab
                  label="For a Product"
                  sx={{
                    fontWeight: "700",
                    fontSize: "13px",
                    lg: { fontSize: "14px" },
                    xl: { fontSize: "16px" },
                  }}
                  onClick={scrollToProduct}
                  {...a11yProps(1)}
                />
                {/* <Tab
                  label="In The Industry"
                  sx={{
                    fontWeight: "700",
                    fontSize: "13px",
                    lg: { fontSize: "14px" },
                    xl: { fontSize: "16px" },
                  }}
                  onClick={scrollToProduct}
                  {...a11yProps(2)}
                /> */}
              </Tabs>
            </Box>
          </div>
          <div
            ref={containerRef}
            style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          >
            <CustomTabPanel value={value} index={0} id="productTabPanel">
              {" "}
              <div className="grid items-center px-2 max-w-7xl grid-cols-2 mx-auto mt-5 md:mt-20 md:grid-cols-6  gap-x-4 gap-y-8 mb-5">
                {Builds.map((item, i) => (
                  <div className="h-[140px]" key={i}>
                    <Build
                      name={item.name}
                      img={item.img}
                      checked={i === selectedBuildIndex}
                      onClick={() => handleCheckboxClick(i)}
                    />
                  </div>
                ))}
              </div>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={0} id="productTabPanel">
            <div
              className="overflow-x-auto whitespace-nowrap"
              style={{
                scrollbarWidth: "none",
                WebkitOverflowScrolling: "touch",
              }}
              ref={scrollContainerRef}
            >
              <div className="inline-flex transition-transform duration-300">
                {Builds.map((item, i) => (
                  <div className="h-[120px]" key={i}>
                    <Build
                      name={item.name}
                      img={item.img}
                      checked={i === selectedBuildIndex}
                      onClick={() => {
                        handleCheckboxClick(i);
                        scrollToItem();
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={1} id="productTabPanel">
            <div className="grid items-center max-w-7xl grid-cols-4 mx-auto mt-5 md:mt-20 md:grid-cols-8 gap-x-4 gap-y-8 mb-5">
              {Builds.map((item, i) => (
                <div className="h-[120px]" key={i}>
                   <Build
                    name={item.name}
                    img={item.img}
                    checked={i === selectedBuildIndex}
                    onClick={() => handleCheckboxClick(i)}
                  />
                </div>
              ))}
            </div>
          </CustomTabPanel> */}
            <CustomTabPanel value={value} index={1} id="productTabPanel">
              <div className="grid items-center px-2 max-w-7xl grid-cols-2 mx-auto mt-5 md:mt-20 md:grid-cols-8 gap-x-4 gap-y-8 mb-5">
                {Product.map((item, i) => (
                  <div className="h-[140px]" key={i}>
                    <Build
                      name={item.name}
                      img={item.img}
                      checked={i === selectedBuildIndex}
                      onClick={() => handleCheckboxClick(i)}
                    />
                  </div>
                ))}
              </div>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={2} id="productTabPanel">
              {" "}
              <div className="grid items-center px-2 max-w-7xl grid-cols-2 mx-auto mt-5 md:mt-20 md:grid-cols-8  gap-x-4 gap-y-8 mb-5">
                {Industry.map((item, i) => (
                  <div className="h-[140px]" key={i}>
                    <Build
                      name={item.name}
                      img={item.img}
                      checked={i === selectedBuildIndex}
                      onClick={() => handleCheckboxClick(i)}
                    />
                  </div>
                ))}
              </div>
            </CustomTabPanel> */}
          </div>
        </Box>

        <div className="text-center">
          <button
            // href="/start-hiring"
            onClick={() =>
              navigate(ROUTES.Connect, {
                state: {
                  selectedBuildIndex:
                    Array.isArray(tabValue) &&
                    tabValue?.find((_, i) => selectedBuildIndex === i),
                },
              })
            }
            className="inline-flex items-center rounded-full justify-center uppercase px-5 py-2.5 text-base font-semibold text-white transition-all duration-200 border border-transparent bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
          >
            Get Started
          </button>
        </div>
      </section>
      <VideoTech />
      <TechTrusted />
      {/* <My_Article /> */}
      <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h4 className="text-xl font-medium text-gray-900">
              Numbers tell the hard works we’ve done in last 6 years
            </h4>
          </div>

          <div className="grid grid-cols-1 gap-6 px-6 mt-8 sm:px-0 lg:mt-16 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-12">
            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">2018</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      helping healthcare since
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  {/* <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg> */}
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 50 50"
                  >
                    <path
                      fill="#c026d3"
                      d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 A 3 3 0 0 0 22 25 A 3 3 0 0 0 22.294922 26.291016 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.708984 27.705078 A 3 3 0 0 0 25 28 A 3 3 0 0 0 28 25 A 3 3 0 0 0 26 22.175781 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z"
                    ></path>
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">3 MO.</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      average MVP time-to-market
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">100+</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      Clinics & Hospitals Served
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="overflow-hidden bg-white border border-gray-200 rounded-lg">
              <div className="px-4 py-6">
                <div className="flex items-start">
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-fuchsia-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                    />
                  </svg>
                  <div className="ml-4">
                    <h4 className="text-4xl font-bold text-gray-900">100%</h4>
                    <p className="mt-1.5 text-lg font-medium leading-tight text-gray-500">
                      Customer satisfaction
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="py-10 bg-gray-100 sm:py-16 lg:py-24" id="Feature">
        <div className="px-4 mx-auto max-w-8xl sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-4xl">
              Scale your software development by hiring a dedicated offshore
              team ready to work
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
              We offer expert teams with brilliance to meet your goals where
              cost to hire a team of full stack developers, graphic designers,
              testers and project managers is less than you expect.
            </p>
          </div>

          <div className="relative mt-12 lg:mt-20">
            <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
              <img className="w-full" src={dotted} alt="" />
            </div>

            <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    1{" "}
                  </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Select a subscription plan and add a task.
                </h3>
                <img
                  className="w-72 h-72 mt-6 object-cover mx-auto"
                  src={require("../../assets/images/one.jpg")}
                  alt=""
                />
                <p className="mt-3 text-sm font-medium text-gray-600">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                </p>
              </div>

              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    2{" "}
                  </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Add your own team to approve or revise until 100% satisfied.
                </h3>
                <img
                  className="w-72 h-72 mt-6 object-cover mx-auto"
                  src={require("../../assets/images/three.jpg")}
                  alt=""
                />
                <img
                  className="w-72 h-72 mt-6 object-cover mx-auto"
                  src={require("../../assets/images/two.jpg")}
                  alt=""
                />
                <p className="mt-4 text-base text-gray-600">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                </p>
              </div>

              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    3{" "}
                  </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Get Results superfast and pay for what you use.
                </h3>
                <img
                  className="w-72 h-72 mt-6 object-cover mx-auto"
                  src={require("../../assets/images/two.jpg")}
                  alt=""
                />
                <img
                  className="w-72 h-72 mt-6 object-cover mx-auto"
                  src={require("../../assets/images/three.jpg")}
                  alt=""
                />
                <p className="mt-4 text-base text-gray-600">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="py-10 bg-white sm:py-16 lg:py-16" id="testinomial">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-4xl">
              Making HealthCare Development Accessible and Affordable
            </h2>
            <p className="max-w-md mx-auto my-4 text-base leading-relaxed text-gray-600">
              Teams with different skill sets readily available to help you move
              faster with your requirements
            </p>
          </div>
          <div className="grid grid-cols-1 gap-6 lg:gap-10 sm:grid-cols-2 md:grid-cols-3">
            {Assembles.map((item, i) => (
              <Assemble
                key={i}
                name={item.name}
                img={item.img}
                content={item.content}
                designation={item.designation}
              />
            ))}
          </div>
        </div>
      </section>
      {/* <Hire /> */}

      <TechPricing />
      {/* <Calculated /> */}
      <Faq />
      <Footer />
    </div>
  );
};

export default HealthTech;
