import { z } from "zod";

const myFormSchema = z.object({
  firstName: z
    .string({ required_error: "first name is required" })
    .min(1, { message: "UserName is required" }),
  lastName: z
    .string({ required_error: "last name is required" })
    .min(1, { message: "UserName is required" }),
  companyName: z
    .string({ required_error: "Company name is required" })
    .min(1, { message: "Company name is required" }),
  userEmail: z
    .string({ required_error: "email is required" })
    .email({ message: "Email is required" }),
  message: z.string().optional(),
});

export default myFormSchema;
