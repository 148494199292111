import makeApiCall from "../MakeApiCall";

const addRequirement = async (payload) => {
  return makeApiCall("POST", `/api/team/add-request`, true, "", payload);
};

const getRequirement = async () => {
  return makeApiCall("GET", `/api/team/request`, true, "", null);
};

const deleteRequirement = async (deletedRecord) => {
  return makeApiCall(
    "DELETE",
    `/api/team/request/${deletedRecord?.id}`,
    true,
    "",
    deletedRecord
  );
};

const updateRequirement = (updateRecord) => {
  return makeApiCall(
    "PATCH",
    `/api/team/request/${updateRecord?.id}`,
    true,
    "",
    updateRecord
  );
};
const addRequirementService = {
  addRequirement,
  getRequirement,
  deleteRequirement,
  updateRequirement,
};

export default addRequirementService;
