// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import Logo from "../assets/images/logo.png";
// import { Link } from "react-router-dom";

// function Header() {
//   return (
//     <header className="">
//       <div className="px-4 mx-auto sm:px-6 lg:px-8 border-b border-[#eee]">
//         <div className="flex items-center justify-between h-16 lg:h-20">
//           <div className="flex-shrink-0">
//             <Link to="/" title="" className="flex">
//               <img
//                 className="w-auto h-12"
//                 src={require("../assets/images/sofyrus_b39d664183.webp")}
//                 alt=""
//               />
//             </Link>
//           </div>

//           <button
//             type="button"
//             className="inline-flex p-1 text-black transition-all duration-200 border border-black lg:hidden focus:bg-gray-100 hover:bg-gray-100"
//           >
//             <svg
//               className="block w-6 h-6"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M4 6h16M4 12h16M4 18h16"
//               />
//             </svg>

//             <svg
//               className="hidden w-6 h-6"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M6 18L18 6M6 6l12 12"
//               ></path>
//             </svg>
//           </button>

//           <div className="hidden ml-auto lg:flex lg:items-center lg:justify-center lg:space-x-10">
//             <a
//               href="#Feature"
//               title=""
//               className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
//             >
//               {" "}
//               Features{" "}
//             </a>

//             <a
//               href="#Testimonials"
//               title=""
//               className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
//             >
//               {" "}
//               Testimonials{" "}
//             </a>

//             <a
//               href="#Pricing"
//               title=""
//               className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
//             >
//               {" "}
//               Pricing{" "}
//             </a>

//             <a
//               href="#Faq"
//               title=""
//               className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
//             >
//               {" "}
//               FAQ{" "}
//             </a>
//             <div className="w-px h-5 bg-black/20"></div>

//             <a
//               href="#"
//               title=""
//               className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
//             >
//               {" "}
//               Log in{" "}
//             </a>

//             <a
//               href="/register"
//               title=""
//               className="inline-flex items-center justify-center px-5 py-2.5 text-base font-semibold text-black border-2 border-black  transition-all duration-200"
//               role="button"
//             >
//               {" "}
//               Sign Up
//             </a>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// }

// export default Header;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className="">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 border-b border-[#eee]">
        <div className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <Link to="/" title="" className="flex">
              {" "}
              <img
                className="w-auto h-12"
                src={require("../assets/images/sofyrus_b39d664183.webp")}
                alt=""
              />
            </Link>
          </div>

          <div className="lg:hidden">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <svg
                className="block w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </Button>
          </div>

          <div className="hidden ml-auto lg:flex lg:items-center lg:justify-center lg:space-x-10">
            <a
              href="#FindTeam"
              title=""
              className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
            >
              {" "}
              Find a Team{" "}
            </a>

            <a
              href="#testinomial"
              title=""
              className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
            >
              {" "}
              Testimonials{" "}
            </a>

            <a
              href="#Pricing"
              title=""
              className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
            >
              {" "}
              Pricing{" "}
            </a>

            <a
              href="#Faq"
              title=""
              className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
            >
              {" "}
              FAQ{" "}
            </a>
            {/* <div className="w-px h-5 bg-black/20"></div> */}

            {/* <a
              href="#"
              title=""
              className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
            >
              {" "}
              Log in{" "}
            </a>

            <a
              href="/register"
              title=""
              className="inline-flex items-center justify-center px-5 py-2.5 text-base font-semibold text-black border-2 border-black  transition-all duration-200"
              role="button"
            >
              {" "}
              Sign Up
            </a> */}
          </div>
        </div>
        <div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              <a
                href="#Feature"
                title=""
                className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
              >
                {" "}
                Features{" "}
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                href="#Testimonials"
                title=""
                className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
              >
                {" "}
                Testimonials{" "}
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                href="#Pricing"
                title=""
                className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
              >
                {" "}
                Pricing{" "}
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                href="#Faq"
                title=""
                className="text-base font-semibold text-black transition-all duration-200 hover:text-opacity-80"
              >
                {" "}
                FAQ{" "}
              </a>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
}

export default Header;
