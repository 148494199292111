import makeApiCall from "../MakeApiCall";

const getUserTask = async () => {
  return makeApiCall("GET", `/api/task`, true, "", null);
};

const getAllTeamMember = async () => {
  return makeApiCall("GET", `/api/team`, true, "", null);
};

const createUserTask = async (createTask) => {
  return makeApiCall("POST", "/api/task/addTask", true, "", createTask);
};

const updateUserTask = async (uTask) => {
  return makeApiCall("PATCH", `/api/task/${uTask?.id}`, true, "", uTask);
};
const deleteUserTask = async (deletedRecord) => {
  return makeApiCall(
    "DELETE",
    `/api/task/${deletedRecord?.id}`,
    true,
    "",
    deletedRecord
  );
};
const updateTaskStatus = async (payload) => {
  ;
  let { Status, statusId } = payload;
  return makeApiCall("PUT", `/api/task/status`, true, "", {
    Status: Status,
    id: statusId,
  });
};

const taskServices = {
  getUserTask,
  updateTaskStatus,
  createUserTask,
  updateUserTask,
  deleteUserTask,
  getAllTeamMember
};

export default taskServices;
