import React, { useState } from "react";
import {
  BsChat,
  BsChatFill,
  BsPen,
  BsTrash,
  BsTrash2,
  BsTrashFill,
} from "react-icons/bs";
import { FaCheck, FaMinus, FaPlus } from "react-icons/fa";
import { ROUTES } from "../routes/RouterConfig";
import { useNavigate } from "react-router-dom";
function Calculated() {
  const navigate = useNavigate();
  // Initialize state for count
  const [count, setCount] = useState(2);
  const [devs, setdevs] = useState(2);
  const [pm, setpm] = useState(0.5);
  const [ui, setui] = useState(0.5);
  const [qa, setqa] = useState(1);
  const [price, setprice] = useState(
    devs * 1800 + pm * 500 + ui * 500 + qa * 500
  );

  
  // Function to handle increment
  // const increment = () => {
  //   setdevs(devs + 1);
  //   if (count <= 10) setpm(pm + 0.25);
  //   if (count <= 5) setui(ui + 0.5);
  //   setqa(devs / 2);
  //   setCount(count + 1);
  //   setprice(devs * 1800 + pm * 500 + ui * 500 + qa * 500);
  // };
  const increment = () => {
    setCount((prevCount) => prevCount + 1);
    setdevs((prevDevs) => {
      const newDevs = prevDevs + 1;
      setpm((prevPm) => {
        const newPm = count <= 10 ? prevPm + 0.25 : prevPm;
        setui((prevUi) => {
          const newUi = count <= 5 ? prevUi + 0.5 : prevUi;
          const newQa = newDevs / 2;
          setqa(newQa);
          const newPrice =
            newDevs * 1800 + newPm * 500 + newUi * 500 + newQa * 500;
          setprice(newPrice);
          return newUi;
        });
        return newPm;
      });
      return newDevs;
    });
  };

  // Function to handle decrement
  // const decrement = () => {
  //   // Check if count is already zero, if not then decrement
  //   if (count <= 2) {
  //     return;
  //   }
  //   setdevs(devs - 1);
  //   if (count <= 10) setpm(pm - 0.25);
  //   if (count <= 5) setui(ui - 0.5);
  //   setqa(devs / 2);
  //   setCount(count - 1);
  //   setprice(devs * 1800 + pm * 500 + ui * 500 + qa * 500);
  // };
  const decrement = () => {
    // Check if count is already at its minimum, if so, return
    if (count <= 2) {
      return;
    }
    setCount((prevCount) => prevCount - 1);
    setdevs((prevDevs) => {
      const newDevs = prevDevs - 1;
      setpm((prevPm) => {
        const newPm = count <= 10 ? prevPm - 0.25 : prevPm;
        setui((prevUi) => {
          const newUi = count <= 5 ? prevUi - 0.5 : prevUi;
          const newQa = newDevs / 2;
          setqa(newQa);
          debugger;
          const newPrice =
            newDevs * 1800 + newPm * 500 + newUi * 500 + newQa * 500;
          setprice(newPrice);
          return newUi;
        });
        return newPm;
      });
      return newDevs;
    });
  };
  return (
    <section className="py-10 bg-gray-100 sm:py-16 lg:py-24" id="Feature">
      <div className="px-8 mx-2 max-w-8xl sm:px-16 pb-8 lg:px-8 bg-gray-100 rounded-xl">
        <div className="w-full flex flex-wrap mx-auto  items-center lg:py-1 sm:py-2 py-3">
          <div className="lg:w-6/12 w-full">
            <h2 className="text-xl font-semibold tracking-wide leading-tight text-black sm:text-2xl lg:text-6xl">
              Calculate Your Team
            </h2>
          </div>
          <div className="lg:w-6/12 w-full">
            <p className="max-w-xl mx-auto mt-4 lg:text-2xl text-lg leading-relaxed text-black">
              Select the appropriate number of developers, and we will
              automatically supplement them with the optimal number of QAs,
              managers, and other specialists to get the project done promptly.
            </p>
          </div>
        </div>

        <div className="relative mt-12 lg:mt-20">
          <div className=" hidden xl:px-44 top-2 md:block md:px-20 lg:px-28"></div>

          <div className="relative grid grid-cols-1 border rounded-xl border-black mb-5  gap-y-12 md:grid-cols-3 gap-x-8">
            <div className="lg:py-8 py-4 lg:pl-4 pl-8 lg:border-r  border-black border-b my-3">
              <h3 className="mt-6 lg:text-2xl  text-xl font-semibold leading-tight text-black md:mt-2 mb-4">
                Choose the number of developers you need
              </h3>
              <div className=" flex lg:justify-center gap-3">
                <button className="text-black" onClick={decrement}>
                  <FaMinus className="lg:text-3xl tex-2xl" />
                </button>
                <div className="lg:w-32 w-20 border-2 border-black lg:h-32 h-20 flex justify-center items-center rounded-xl">
                  <h2 className=" text-black lg:text-5xl text-3xl">{count}</h2>
                </div>

                <button className="text-black" onClick={increment}>
                  <FaPlus className="lg:text-3xl text-2xl" />
                </button>
              </div>
            </div>

            <div className="lg:py-8 py-3 lg:border-r border-black border-b my-3 lg:px-1 sm:px-2 px-8">
              <h3 className="lg:mt-6 mt-0 lg:text-2xl text-xl font-semibold leading-tight text-black md:mt-2 mb-4">
                Your optimal team composition
              </h3>
              <ul className="list-type-none text-black text-2xl">
                <li className="py-1.5 flex gap-2">
                  {" "}
                  <FaCheck className="lg:text-2xl text-lg" />
                  <h4 className="lg:text-2xl text-lg">{devs} Developers</h4>
                </li>
                <li className="py-1.5 flex gap-2">
                  <FaCheck className="lg:text-2xl text-lg" />
                  <h4 className="lg:text-2xl text-lg"> {qa} QA</h4>
                </li>
                <li className="py-1.5 flex gap-2">
                  <FaCheck className="lg:text-2xl text-lg" />
                  <h4 className="lg:text-2xl text-lg"> {ui} Designers</h4>
                </li>
                <li className="py-1.5 flex gap-2">
                  <FaCheck className="lg:text-2xl text-lg" />
                  <h4 className="lg:text-2xl text-lg">{pm} PM</h4>
                </li>
              </ul>
            </div>

            <div className="lg:py-8 py-3 pr-4 lg:px-1 sm:px-2 px-8">
              <h3 className="lg:mt-6 mt-0 lg:text-2xl text-xl font-semibold leading-tight text-black md:mt-2">
                Your approximate price
              </h3>
              <h3 className="mt-3 lg:text-5xl text-3xl font-semibold leading-tight text-black">
                {price}
              </h3>
              <p className=" lg:text-2xl text-xl font-semibold leading-tight text-black">
                per month
              </p>
              <p className="mt-3 lg:text-lg text-sm font-medium leading-tight text-black">
                *Please note that the cost is calculated according to the
                expert’s rates (see above)
              </p>

              <button
                type="submit"
                onClick={() => navigate(ROUTES.Hiring)}
                className=" items-center lg:w-10/12 w-full  mx-auto uppercase gap-2 mt-3 lg:px-5 px-1 py-3 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#238647] focus:outline-none hover:opacity-80 focus:opacity-80"
              >
                Discuss the Project
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Calculated;
