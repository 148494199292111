import React, { useEffect, useRef, useState } from "react";
import video1 from "../assets/teams-sofyrus-landscape1.mp4";

function Video() {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    let video = document.querySelector("video");
    let observer = new IntersectionObserver(
      (entry) => {
        if (entry.intersectionRatio != 0.5 && !playing) {
          videoRef.current.pause();
          setPlaying(true);
        } else if (playing) {
          videoRef.current.play();
          setPlaying(false);
        }
      },
      { threshold: 1 }
    );
    observer.observe(video);
    // Cleanup function to disconnect the observer
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleVideoPress = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <div
      className="bg-gradient-to-b from-gray-50 to-gray-100"
      id="Testimonials"
    >
      {/* <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="flex justify-center item-center rounded-lg">
          <iframe
            width="700"
            className="rounded-md box"
            height="315"
            src={video1}
            title="YouTube video player"
            // frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            ref={videoRef}
          ></iframe>
          <video width="50%" controls autoPlay src={video1} type="video/mp4" />
        </div>
      </section> */}
      <section className=" 2xl:py-24 px-6  bg-gray-50">
        <div className="px-4 mx-auto  max-w-8xl sm:px-6 lg:px-8 2xl:rounded-xl">
          <div className="py-10   lg:py-24 2xl:pl-12">
            <div className="grid items-center sm:-py-40 md:-py-40 grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-8 2xl:gap-x-20">
              <div className="lg:order-2 md:pr-6">
                <video
                  className="rounded-lg shadow-md"
                  width="100%"
                  controls
                  // autoPlay
                  src={video1}
                  loop
                  height="315"
                  type="video/mp4"
                  ref={videoRef}
                  onClick={handleVideoPress}
                />
                {/* <iframe
                ref={iframeRef}
                className="rounded-lg shadow-md"
                width="100%"
                  height="315"
                  src={video1}
                  allow="autoplay"
                ></iframe> */}
              </div>
              <div className="lg:order-1 ">
                <h2 className="text-2xl xl:text-4xl font-bold leading-tight text-black sm:text-3xl lg:text-4xl lg:leading-snug">
                  Scale your business with Team Subscription
                  <br className="hidden xl:block" />
                </h2>

                <ul className="grid grid-cols-1 mt-4 sm:mt-10 sm:grid-cols-2 gap-x-5 xl:gap-x-8 gap-y-4 xl:gap-y-6">
                  <li className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-3 lg:text-sm text-sm xl:text-base font-medium text-black">
                      {" "}
                      Diverse skill sets{" "}
                    </span>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-3 lg:text-sm text-sm xl:text-base font-medium text-black">
                      {" "}
                      Flexible resource allocation{" "}
                    </span>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-3 lg:text-sm text-sm xl:text-base font-medium text-black">
                      {" "}
                      Reduced overheads{" "}
                    </span>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-3 lg:text-sm text-sm xl:text-base font-medium text-black">
                      {" "}
                      Rapid Deployment{" "}
                    </span>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-3  lg:text-sm text-sm xl:text-base font-medium text-black">
                      {" "}
                      Enhance agility{" "}
                    </span>
                  </li>

                  <li className="flex items-center">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="ml-3 lg:text-sm text-sm xl:text-base font-medium text-black">
                      {" "}
                      Streamlined operations{" "}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Video;
