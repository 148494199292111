import React from "react";

function Trusted() {
  return (
    <div className="bg-gradient-to-b from-gray-50 to-gray-100" id="Testimonials">
      <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-8xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-2xl font-bold text-gray-800 sm:text-4xl sm:leading-tight">
              Trusted by World Class Companies
            </h2>
          </div>

          <div className="grid items-center max-w-4xl grid-cols-2 mx-auto mt-12 md:mt-20 md:grid-cols-5 gap-x-16 gap-y-8">
            <div>
              <img
                className="object-contain w-full  mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/Untitled_1243_1bf2b4c712.webp")}
                alt=""
              />
            </div>

            <div>
              <img
                className="object-contain w-full  mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/Untitled_8da9c09460.webp")}
                alt=""
              />
            </div>

            <div>
              <img
                className="object-contain w-full  mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/providity_90f7eb7a11.webp")}
                alt=""
              />
            </div>

            <div>
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/patient7_3e01e8d4b0.webp")}
                alt=""
              />
            </div>

            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/dmw.webp")}
                alt=""
              />
            </div>

            <div className="hidden md:block">
              <img
                className="object-contain w-full  mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/Untitled_20_dc6124a091.webp")}
                alt=""
              />
            </div>

            <div className="hidden md:block">
              <img
                className="object-contain w-full  mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/vsg_1537d3b26d.webp")}
                alt=""
              />
            </div>

            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/inergio_d853b33aec.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/ashghal.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/atalgo.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/blink.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/megaminds.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/hataso.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/techmatrix.webp")}
                alt=""
              />
            </div>
            <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/entrancely.webp")}
                alt=""
              />
            </div>
            {/* <div className="hidden md:block">
              <img
                className="object-contain w-full mx-auto grayscale cursor-pointer hover:grayscale-0"
                src={require("../assets/images/anannt.webp")}
                alt=""
              />
            </div> */}
          </div>
      
        </div>
      </section>
    </div>
  );
}

export default Trusted;
