import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Select from "@mui/material/Select";
import { z } from "zod";
import myFormSchema from "../../schema/HiringForm";

const Connect = () => {
  const location = useLocation();
  console.log(location);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  // console.log("locationState", location.state.selectedBuildIndex.name);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState({
    firstName: "",
    lastName: "",
  });
  const [userDetails, setUserDetails] = useState({
    userName: "",
    companyName: "",
    userEmail: "",
    message: "",
  });

  const changeDir = (dir) => {
    navigate(dir);
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.selectedBuildIndex &&
      location.state.selectedBuildIndex.name
    ) {
      setSelectedValue(location.state.selectedBuildIndex?.name);
    }
  }, [location?.state?.selectedBuildIndex?.name]);

  const HandleChange = (key, value) => {
    setUserDetails({ ...userDetails, [key]: value });
  };

  const fullNameHandler = (key, value) => {
    setFullName({
      ...fullName,
      [key]: value,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(myFormSchema),
    defaultValues: {
      userName: "",
      companyName: "",
      userEmail: "",
      message: "less than $20,000",
    },
  });

  const onSubmit = async (data) => {
    const uDetails = { ...data };
    uDetails.userName = `${data.firstName} ${data.lastName}`;
    uDetails.message =
      selectedValue && data.message
        ? `${selectedValue} ${data.message}`
        : selectedValue || data.message;
    debugger;
    // uDetails.message = selectedValue ? selectedValue : data.message;
    setShowMessage(true);
    try {
      const response = await axios.post(
        "https://teamsapi.sofyrus.com/api/user/sendDemo",
        uDetails
      );

      let { status, data: responseData } = response;
      // setShowMessage(true);
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setShowMessage(true);
  //   const uDetails = { ...userDetails };
  //   uDetails["userName"] = `${fullName?.firstName} ${fullName?.lastName}`;
  //   uDetails["message"] = selectedValue
  //     ? `${selectedValue}`
  //     : userDetails.message;
  //   try {
  //     const response = await axios.post(
  //       "https://teamsapi.sofyrus.com/api/user/sendDemo",
  //       uDetails
  //     );
  //     let { status, data } = response;
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Handle error
  //   }
  // };

  const handleChangeMember = (e) => {
    debugger;
    setUserDetails((Uvalue) => ({ ...Uvalue, message: e.target.value }));
    /* setMyteamMember(e.target.value); */
  };

  return (
    <div className="Login ">
      <main className="profile-page">
        <section className="bg-white">
          <div className="grid grid-cols-1 lg:grid-cols-2 h-[100vh]">
            <div className="relative flex items-start px-4 pb-10 lg:pt-4 pt-16 sm:pb-16 md:justify-center lg:pb-24 bg-gradient-to-b from-gray-900 to-gray-700 sm:px-6 lg:px-8">
              {/* <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div> */}

              <div className="relative flex flex-col gap-16">
                <div className="flex-shrink-0 px-1 ">
                  <Link to="/" title="" className="flex">
                    {" "}
                    <img
                      className="w-auto h-12 "
                      src={require("../../assets/images/sofyrus_b39d664183.webp")}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="grid justify-center content-center lg:h-[70vh]">
                  <div className="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-16 pr-8 xl:max-w-2xl">
                    <h3 className="lg:text-4xl text-2xl font-bold text-white mb-2">
                      Let's Discuss Your HealthCare Solutions
                    </h3>
                    <br></br>
                    {/* <div className="flex"> */}
                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 A 3 3 0 0 0 22 25 A 3 3 0 0 0 22.294922 26.291016 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.708984 27.705078 A 3 3 0 0 0 25 28 A 3 3 0 0 0 28 25 A 3 3 0 0 0 26 22.175781 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z"></path>
                  </svg> */}
                    <p className="text-xl font-normal text-white">
                      <span className="text-xl font-bold text-white">
                        {" "}
                        Tele Consultation Solutions{" "}
                      </span>
                    </p>
                    <br></br>
                    {/* </div> */}

                    <p className="text-xl font-normal text-white">
                      <span className="text-xl font-bold text-white">
                        {" "}
                        Clinical Decision Support System{" "}
                      </span>
                      {/* - we prefer to work in 2-week sprints */}
                    </p>
                    <br></br>
                    <p className="text-xl font-normal text-white">
                      <span className="text-xl font-bold text-white">
                        {" "}
                        DICOM Viewer & PACS{" "}
                      </span>
                      {/* - if you aren’t satisfied with the first 2 weeks of work,
                    your fee will be $0 */}
                    </p>
                    <br></br>
                    <p className="text-xl font-normal text-white">
                      <span className="text-xl font-bold text-white">
                        {" "}
                        EHR- EMR & Clinical Solution{" "}
                      </span>
                      {/* - both options are available */}
                    </p>
                    <br></br>
                    <p className="text-xl font-normal text-white">
                      <span className="text-xl font-bold text-white">
                        {" "}
                        Remote Patient Monitoring Solution{" "}
                      </span>
                      {/* - both options are available */}
                    </p>
                    <br></br>
                    <p className="text-xl font-normal text-white">
                      <span className="text-xl font-bold text-white">
                        {" "}
                        FHIR/ HL7 Consultaion{" "}
                      </span>
                      {/* - both options are available */}
                    </p>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center px-4 py-7 bg-white sm:px-6 lg:px-8 sm:py-2 lg:py-24">
              {showMessage ? (
                <div className="space-y-5 ">
                  <h3 className=" text-2xl lg:text-4xl w-[100%]  md:text-xl mx-w-[60%] font-bold text-black mb-3 lg:px-6 px-4 ">
                    Thank you for connecting with us. <br></br>We will come back
                    to you within the next 24 hours
                  </h3>
                </div>
              ) : (
                <div className="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-5">
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          First Name
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600 pb-1">
                          <input
                            type="text"
                            name=" userName"
                            {...register("firstName")}
                            // value={fullName.firstName}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              fullNameHandler("firstName", Event.target.value)
                            }
                          />
                        </div>
                        {errors.firstName?.message.length && (
                          <span className="text-red-500 text-sm  font-medium">
                            {errors.firstName?.message}
                          </span>
                        )}
                      </div>
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Last Name
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600 pb-1">
                          <input
                            type="text"
                            name="userName"
                            // value={fullName.lastName}
                            {...register("lastName")}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              fullNameHandler("lastName", Event.target.value)
                            }
                          />
                        </div>
                      </div>
                      {errors?.lastName?.message.length ? (
                        <span className="text-red-500 text-sm font-medium">
                          {errors.lastName?.message}
                        </span>
                      ) : null}
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Company Name
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600 pb-1">
                          <input
                            type="text"
                            name="companyName"
                            {...register("companyName")}
                            // value={userDetails.companyName}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              HandleChange("companyName", Event.target.value)
                            }
                          />
                        </div>
                      </div>
                      {errors.companyName?.message.length && (
                        <span className="text-red-500 text-sm font-medium">
                          {errors.companyName?.message}
                        </span>
                      )}

                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Work Email
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600 pb-1">
                          <input
                            type="email"
                            name=" userEmail"
                            {...register("userEmail")}
                            // value={userDetails.userEmail}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              HandleChange("userEmail", Event.target.value)
                            }
                          />
                        </div>
                        {errors.userEmail?.message.length && (
                          <span className="text-red-500 text-sm font-medium">
                            {errors.userEmail.message}
                          </span>
                        )}
                      </div>
                      {/* <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Set your Budget
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                          <input
                            type="text"
                            name="message"
                            value={userDetails.message}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              HandleChange("message", Event.target.value)
                            }
                          />
                        </div>
                      </div> */}

                      {/* <div>
                      <label
                        for=""
                        className="text-sm font-medium text-gray-900 red"
                      >
                        How did you hear about us?
                      </label>
                      <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                        <input
                          type="text"
                          name=""
                          id=""
                          className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                        />
                      </div>
                    </div> */}

                      <div>
                        <button
                          type="submit"
                          className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                          /*   onClick={(Event) => handleSubmit(Event)} */
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Connect;
