import React from "react";

const Assemble = ({ name, img, content, designation }) => {
  return (
    <div className="flex flex-col bg-white border border-gray-200 rounded-md">
      <div className="flex flex-col justify-between flex-1 p-8">
        <div className="flex-1">
          <blockquote>
            <p className="text-lg text-gray-800">{content}</p>
          </blockquote>
        </div>

        <div className="mt-8">
          <div className="w-full h-0 mb-8 border-t-2 border-gray-200 border-dotted"></div>
          <div className="flex items-center">
            <img
              className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
              src={img}
              alt=""
            />
            <div className="ml-3 max-w-xs sm:max-w-sm">
              <p className="text-base font-semibold text-gray-800 truncate">
                {name}
              </p>
              <p className="text-base  text-gray-500 truncate text-wrap">
                {designation}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assemble;
