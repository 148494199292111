import React from "react";

function Hire() {
  return (
    <section className="pt-10 lg:px-1 px-3 pb-8 overflow-hidden bg-white sm:pt-16 lg:pt-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-4xl">
            Hire offshore developer with specialized skill sets
          </h2>
          {/* <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">For your extra-odinary Projects.</p> */}
        </div>
      </div>
      <div className="grid items-center max-w-4xl grid-cols-4 mx-auto mt-12 md:mt-20 md:grid-cols-5 gap-x-12 gap-y-8 mb-5">
        <div>
          <img
            className="object-contain w-full  mx-auto  cursor-pointer"
            src={require("../assets/images/android.jpg")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full  mx-auto cursor-pointer "
            src={require("../assets/images/figma.jpg")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full  mx-auto  cursor-pointer "
            src={require("../assets/images/node.jpg")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full mx-auto  cursor-pointer"
            src={require("../assets/images/javascript.jpg")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full mx-auto  cursor-pointer "
            src={require("../assets/images/css.jpg")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full  mx-auto  cursor-pointer "
            src={require("../assets/images/react.png")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full  mx-auto cursor-pointer "
            src={require("../assets/images/post.png")}
            alt=""
          />
        </div>

        <div>
          <img
            className="object-contain w-full mx-auto  cursor-pointer"
            src={require("../assets/images/ios.jpg")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto cursor-pointer"
            src={require("../assets/images/gatsby.webp")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto  cursor-pointer "
            src={require("../assets/images/mysql.png")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto cursor-pointer"
            src={require("../assets/images/laravel.jpg")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto  cursor-pointer"
            src={require("../assets/images/wordpress.png")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto cursor-pointer"
            src={require("../assets/images/aws.png")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto  cursor-pointer "
            src={require("../assets/images/php.jpg")}
            alt=""
          />
        </div>
        <div>
          <img
            className="object-contain w-full mx-auto cursor-pointer "
            src={require("../assets/images/net.png")}
            alt=""
          />
        </div>
      </div>
    </section>
  );
}

export default Hire;
