import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import { ToastContainer, toast } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./Services/Payment/CheckoutForm";

const stripePromise = loadStripe(
  "pk_test_51Mq9icSC1Y9F2jP57EuLBKoFNlIEgkVabF4iNpWHAvrOyrO6WX3EXiKMpvFbp4jweJaYzjHQrRLrmYhdX4xTaMJi00u6fcP1hg"
);

function App() {
  const options = {
    mode: "payment",
    amount: 1099,
    currency: "inr",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className="App">
      <Elements stripe={stripePromise} options={options}>
        <GoogleOAuthProvider clientId="119712332327-vr0hpialnqcosf1mcslnrfvha14vtvju.apps.googleusercontent.com">
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          <ToastContainer />
        </GoogleOAuthProvider>
      </Elements>
    </div>
  );
}

export default App;
