import React, { useState } from "react";
import { ROUTES } from "../../routes/RouterConfig";
import { useNavigate } from "react-router-dom/dist";
import bgImage from "../../assets/images/aslamKhan-removebg-preview.png";
import bgImg1 from "../../assets/images/imglanding2.png";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Pricing2 from "../../components/Pricing2";
import Footer from "../../components/Footer";
import mode1 from "../../assets/images/mode1.png";
import mode2 from "../../assets/images/mode2.png";
import mode3 from "../../assets/images/mode3.png";
import TeamsLandingPage from "../../components/TeamsLandingPage";

// page one content
const PageOneContent = {
  heading: "Why you should Hire an Agency Now?",
  subHeading1: " Agencies deliver 30% efficiency boost.",
  subHeading2: " Save up to 40% in operational costs.",
  subHeading3: "  Flexibility reduces overheads by 25%.",
  subHeading4: " Solutions delivered 50% faster.",
  subHeading5: " Allocate 70% more resources for growth.",
  buttonText: "Click to Load More",
  image: bgImage,
};
const PageTwoContent = {
  heading: " You might be over paying by almost 40% with in-house hiring!",
  subHeading1: " Average company in US spends around $5K just as hiring fee.",
  subHeading2: " Employee trainings can cost upto $6K annually",
  subHeading3: " 20% of the base salary is kept for additional benefits",
  buttonText: "How can I save costs",
  image: mode1,
};
const PageThreeContent = {
  heading: "Your Team Our Developers?",
  subHeading1:
    " Access On-Demand Team Support and Scale Your Projects Effortlessly! Say Goodbye to Overheads and Hello to a Diverse Talent Pool for All Your Project Needs. With the Subscription model hire teams easily with dedicated Account Managers",
  buttonText: "What will be the ROI?",
  image: mode2,
};
const PageFourContent = {
  heading: "Maximize ROI with Team Subscription?",
  subHeading1:
    " Unlock Incredible Savings: Get Dedicated Teams at a Subscription and Wave Goodbye to Team Worries! Allowing you to save minimum 40% costs on every hire.",
  subHeading2:
    " No More Stressing Over Replacements or Team Member Turnover – We've Got You Covered.",
  buttonText: " Show me the Price",
  image: mode3,
};
function Landing_Page1() {
  const navigate = useNavigate();
  const [index, setIndex] = useState("1");
  document.title = "Hire an Agency";
  return (
    // <div className="bg-gray-300 h-[45vw]">
    <div className="">
      {index == 1 ? (
        <div className="bg-gradient-to-b from-gray-900 to-gray-700 2xl:py-20 lg:py-20 py-4 lg:px-12">
          <div className="flex-shrink-0 px-4 py-4">
            <Link to="/" title="" className="flex">
              {" "}
              <img
                className="w-auto h-12 "
                src={require("../../assets/images/sofyrus_b39d664183.webp")}
                alt=""
              />
            </Link>
          </div>
          <section className="py-2 container px-8 mx-auto lg:mx-auto md:mx-auto 2xl:mx-auto xl:mx-auto sm:py-16 lg:py-24 flex items-center min-h-[88vh]">
            <div className="px-0 mx-auto  sm:px-6 lg:px-8">
              <div className="grid items-center grid-cols-1  gap-12 lg:grid-cols-2 ">
                <div className="">
                  <h1
                    style={{ lineHeight: 1.12 }}
                    className="text-2xl font-bold text-white sm:text-2xl lg:text-5xl"
                  >
                    Is Outsourcing Right for You?{" "}
                  </h1>
                  <div className="mt-4">
                    <span
                      className="mt-5 text-lg leading-8  text-white"
                      //  style={{ whiteSpace: "nowrap" }}
                    >
                      Do you know even Google has a workforce of 120,000+
                      offshore workers to develop software solutions?<br></br>
                      Ever wondered why?
                      <br></br>
                      Then ask yourself if you are you struggling to find the
                      right talent for your projects? <br></br>
                      Or Are you looking to create custom solutions, mobile
                      applications or scale operations with AI/ML, RPA or
                      no-code technologies? <br></br>
                      Finally, are you seeking an experienced team to help you
                      leverage cutting edge technologies to stay ahead.<br></br>
                    </span>
                  </div>
                  <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                    <button
                      onClick={() => {
                        setIndex("2");
                      }}
                      className="inline-flex items-center justify-center px-10 py-3 rounded text-base font-semibold text-white transition-all duration-200 bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                    >
                      {" "}
                      Click to Load More{" "}
                    </button>
                  </div>
                  <div className="mt-4 px-1">
                    {/* <h2 className="text-white text-2xl font-semibold">Subscribe</h2> */}
                  </div>
                </div>

                <div
                  className="flex justify-center items-end mr-0 relative"
                  style={{ position: "relative" }}
                >
                  <div
                    // className="bg-[#1f2125] border-1 shadow-lg h-[200px] w-[200px]"
                    style={{
                      position: "relative",
                      top: 30,
                      height: 390,
                      width: 380,
                      backgroundColor: "#1f2125",
                      boxShadow: "1px 2px 15px #212428",
                      borderRadius: 4,
                    }}
                  ></div>
                  <img
                    className="object-contain lg:top-[39%] top-[68%] lg:h-[540px] h-[310px]"
                    style={{
                      position: "absolute",
                      zIndex: 9,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                    }}
                    src={bgImage}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : index == 2 ? (
        <div className="bg-gradient-to-b from-gray-900 to-gray-700 lg:px-12">
          <div className="flex-shrink-0 px-4 py-4">
            <Link to="/" title="" className="flex">
              {" "}
              <img
                className="w-auto h-12 "
                src={require("../../assets/images/sofyrus_b39d664183.webp")}
                alt=""
              />
            </Link>
          </div>
          <section className="py-2 container px-8 mx-auto lg:mx-auto md:mx-auto 2xl:mx-auto xl:mx-auto sm:py-16 lg:py-24 flex items-center min-h-[88vh]">
            <div className="px-0 mx-auto sm:px-6 lg:px-8">
              <div className="grid items-center grid-cols-1  gap-12 lg:grid-cols-2 ">
                <div className="">
                  <h1
                    style={{ lineHeight: 1.12 }}
                    className="text-2xl font-bold text-white sm:text-2xl lg:text-5xl"
                  >
                    Are you Ready to Scale with Outsourcing?{" "}
                  </h1>
                  <div className="mt-4">
                    <span
                      className="mt-5 text-lg leading-8  text-white"
                      //  style={{ whiteSpace: "nowrap" }}
                    >
                      You might be over paying by almost 40% with in-house
                      hiring.<br></br>
                      <br></br> Average company in US spends around $5K just as
                      hiring fee.<br></br>
                      <br></br>
                      Employee trainings can cost upto $6K annually.
                      <br></br>
                      <br></br>
                      Retaining the Employees is another challenge companies
                      face. <br></br>
                    </span>
                  </div>
                  <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                    <button
                      onClick={() => {
                        setIndex("3");
                      }}
                      className="inline-flex items-center justify-center px-10 py-3 rounded text-base font-semibold text-white  bg-green-500 focus:outline-none hover:opacity-80"
                    >
                      {" "}
                      How can I save costs{" "}
                    </button>
                  </div>
                  <div className="mt-4 px-1">
                    {/* <h2 className="text-white text-2xl font-semibold">Subscribe</h2> */}
                  </div>
                </div>

                <div
                  className="flex justify-center items-end mr-0 relative"
                  style={{ position: "relative" }}
                >
                  <div
                    // className="bg-[#1f2125] border-1 shadow-lg h-[200px] w-[200px]"
                    className=" lg:top-[30px] top-1"
                    style={{
                      position: "relative",

                      height: 390,
                      width: 380,
                      backgroundColor: "#1f2125",
                      boxShadow: "1px 2px 15px #212428",
                      borderRadius: 4,
                    }}
                  ></div>
                  <img
                    className="object-contain lg:top-[56%] top-[68%] lg:h-[440px] h-[257px]"
                    style={{
                      position: "absolute",
                      zIndex: 9,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                    }}
                    src={mode1}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : index == 3 ? (
        <div className="bg-gradient-to-b from-gray-900 to-gray-700 lg:px-12 ">
          <div className="flex-shrink-0 px-4 py-4">
            <Link to="/" title="" className="flex">
              {" "}
              <img
                className="w-auto h-12 "
                src={require("../../assets/images/sofyrus_b39d664183.webp")}
                alt=""
              />
            </Link>
          </div>
          <section className="py-2 container px-8 mx-auto lg:mx-auto md:mx-auto 2xl:mx-auto xl:mx-auto sm:py-16 lg:py-24 flex items-center min-h-[88vh]">
            <div className="px-0 mx-auto  sm:px-6 lg:px-8">
              <div className="grid items-center grid-cols-1  gap-12 lg:grid-cols-2 ">
                <div className="">
                  <h1
                    style={{ lineHeight: 1.12 }}
                    className="text-2xl font-bold text-white sm:text-2xl lg:text-5xl"
                  >
                    Why Choose Sofyrus?{" "}
                  </h1>
                  <div className="mt-4">
                    <span
                      className="mt-5 text-lg leading-8  text-white"
                      //  style={{ whiteSpace: "nowrap" }}
                    >
                      Our philosophy <b>Your team our Developers.</b> <br></br>{" "}
                      <br></br>
                      We pride ourselves on our track record of success. With a
                      stellar project delivery rate and significant cost
                      savings, we've helped countless businesses achieve their
                      goals. <br></br> <br></br>
                      Our outsourcing solutions have been proven to lower costs
                      by more than 50% and reduce time to market, giving you a
                      competitive edge.
                    </span>
                  </div>
                  <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                    <button
                      onClick={() => {
                        setIndex("4");
                      }}
                      className="inline-flex items-center justify-center px-10 py-3 rounded text-base font-semibold text-white transition-all duration-200 bg-green-500 focus:outline-none hover:opacity-80"
                    >
                      {" "}
                      What will be the ROI?{" "}
                    </button>
                  </div>
                  <div className="mt-4 px-1">
                    {/* <h2 className="text-white text-2xl font-semibold">Subscribe</h2> */}
                  </div>
                </div>

                <div
                  className="flex justify-center items-end mr-0 relative"
                  style={{ position: "relative" }}
                >
                  <div
                    // className="bg-[#1f2125] border-1 shadow-lg h-[200px] w-[200px]"
                    className="lg:top-[30px] top-[8px] "
                    style={{
                      position: "relative",

                      height: 330,
                      width: 350,
                      backgroundColor: "#1f2125",
                      boxShadow: "1px 2px 15px #212428",
                      borderRadius: 4,
                    }}
                  ></div>
                  <img
                    className="object-contain lg:top-[69%] top-[70%]  lg:h-[400px] h-[210px]"
                    style={{
                      position: "absolute",
                      zIndex: 9,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                    }}
                    src={mode2}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : index == 4 ? (
        <div className="bg-gradient-to-b from-gray-900 to-gray-700 lg:px-12 ">
          <div className="flex-shrink-0 px-4 py-4">
            <Link to="/" title="" className="flex">
              {" "}
              <img
                className="w-auto h-12 "
                src={require("../../assets/images/sofyrus_b39d664183.webp")}
                alt=""
              />
            </Link>
          </div>
          <section className="py-2 container px-8 mx-auto lg:mx-auto md:mx-auto 2xl:mx-auto xl:mx-auto sm:py-16 lg:py-24 flex items-center min-h-[88vh]">
            <div className="px-0 mx-auto  sm:px-6 lg:px-8">
              <div className="grid items-center grid-cols-1  gap-12 lg:grid-cols-2 ">
                <div className="">
                  <h1
                    style={{ lineHeight: 1.12 }}
                    className="text-2xl font-bold text-white sm:text-2xl lg:text-5xl"
                  >
                    Maximize ROI with Team Subscription?{" "}
                  </h1>
                  <div className="mt-4">
                    <span
                      className="mt-5 text-lg leading-8  text-white"
                      //  style={{ whiteSpace: "nowrap" }}
                    >
                      Unlock Incredible Savings: Get Dedicated Teams at a
                      Subscription and Wave Goodbye to Team Worries! Allowing
                      you to save minimum 40% costs on every hire.<br></br>
                      No More Stressing Over Replacements or Team Member
                      Turnover – We've Got You Covered.
                    </span>
                  </div>
                  <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                    <button
                      onClick={() => {
                        setIndex("5");
                      }}
                      className="inline-flex items-center justify-center px-10 py-3 rounded text-base font-semibold text-white transition-all duration-200 bg-green-500 focus:outline-none hover:opacity-80"
                    >
                      {" "}
                      Show me the Price{" "}
                    </button>
                  </div>
                  <div className="mt-4 px-1">
                    {/* <h2 className="text-white text-2xl font-semibold">Subscribe</h2> */}
                  </div>
                </div>

                <div
                  className="flex justify-center items-end mr-0 relative"
                  style={{ position: "relative" }}
                >
                  <div
                    // className="bg-[#1f2125] border-1 shadow-lg h-[200px] w-[200px]"
                    className="lg:top-[5px] top-[6px]"
                    style={{
                      position: "relative",

                      height: 390,
                      width: 380,
                      backgroundColor: "#1f2125",
                      boxShadow: "1px 2px 15px #212428",
                      borderRadius: 4,
                    }}
                  ></div>
                  <img
                    className="object-contain lg:top-[44%] top-[68%] lg:h-[540px] h-[260px]"
                    style={{
                      position: "absolute",
                      zIndex: 9,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                    }}
                    src={mode3}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : index == 5 ? (
        <div className="bg-gradient-to-b from-gray-900 to-gray-700 lg:px-12 ">
          <div className="flex-shrink-0 px-4 pt-4 py-2">
            <Link to="/" title="" className="flex">
              {" "}
              <img
                className="w-auto h-12 "
                src={require("../../assets/images/sofyrus_b39d664183.webp")}
                alt=""
              />
            </Link>
          </div>
          <Pricing2 />
          <Footer />
        </div>
      ) : null}
    </div>
  );
}

export default Landing_Page1;
