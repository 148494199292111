import React, { useState } from "react";
import bannerImg from "../assets/images/hero-img.png";
// import bannerImg from "../assets/images/bannerHeroImg.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import axios from "axios";
import mode1 from "../assets/images/mode1.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "../routes/RouterConfig";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Banner() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userDetails, setUserDetails] = useState({
    userEmail: "",
    companyName: "",
    userName: "",
    message: "",
  });

  const handleChange = (key, value) => {
    setUserDetails((preState) => ({
      ...preState,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://teamsapi.sofyrus.com/api/user/sendDemo",
        userDetails
      );
      console.log("response", response);
      const { data, status } = response;
      if (status === 201 && data) {
        toast.success(
          "Thank you for connecting with us!  We'll reach out to you soon.",
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
        setUserDetails({
          userEmail: "",
          companyName: "",
          userName: "",
          message: "",
        });
      }
      setOpen(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <div className="bg-[#edfdf3]" id="Banner">
      <section className="py-10 sm:py-16 lg:py-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <motion.div
              initial={{ x: "-100vw" }} // Start from the left off-screen
              animate={{ x: 0 }} // Animate to the default position
              transition={{ type: "spring", duration: 2 }} // Transition configuration
              // className="flex flex-col justify-center align-middle p-3 text-center lg:text-left md:text-left lg:p-10 md:p-5 mb-4"
            >
              <div>
                <h1 className="text-3xl font-bold text-black sm:text-4xl lg:text-5xl lg:leading-12">
                  Build Your Product <br></br> with a Result Driven{" "}
                  <div className="relative inline-flex">
                    <span className="absolute inset-x-0 bottom-0 border-b-[30px] border-[#4ADE80]"></span>
                    <h1 className="relative text-3xl font-bold text-black sm:text-6xl lg:text-5xl lg:leading-10">
                      Software Studio
                    </h1>
                  </div>
                </h1>
                <div className="mt-4">
                  <span
                    className="mt-5 text-lg"
                    //  style={{ whiteSpace: "nowrap" }}
                  >
                    Work with a long-term tech partner who will fully take care
                    of your product development.
                  </span>
                </div>
                <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
                  <button
                    onClick={() => navigate(ROUTES.Hiring)}
                    className="inline-flex items-center justify-center px-10 py-4 text-base font-semibold text-white transition-all duration-200 bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                  >
                    {" "}
                    Explore More{" "}
                  </button>
                  {/* <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className="modal-ui lg:w-[75%] w-full flex flex-col  justify-center">
                    <div className="flex flex-wrap justify-between  gap-3 items-center">
                      <div className="lg:w-[58%] 2xl:w-[58%] xl:w-[58%] w-full">
                        <h1 className=" text-2xl font-bold text-black my-3">
                          Make the Right Choice
                        </h1>
                        <h4 className="text-base font-medium"></h4>
                        <div className="flex w-full gap-3 my-4">
                          <TextField
                            className="w-1/2 "
                            id="outlined-basic"
                            label="Your Name"
                            variant="outlined"
                            color="success"
                            value={userDetails.userName}
                            onChange={(e) =>
                              handleChange("userName", e.target.value)
                            }
                          />
                          <TextField
                            className="w-1/2 "
                            id="outlined-basic"
                            label="Your Email"
                            variant="outlined"
                            color="success"
                            value={userDetails.userEmail}
                            onChange={(e) =>
                              handleChange("userEmail", e.target.value)
                            }
                          />
                        </div>
                        <TextField
                          className="w-full mb-2"
                          id="outlined-basic"
                          label="Your Company Name"
                          variant="outlined"
                          color="success"
                          value={userDetails.companyName}
                          onChange={(e) =>
                            handleChange("companyName", e.target.value)
                          }
                        />
                        <textarea
                          class="form-control my-2 "
                          id="message-text"
                          placeholder="Message"
                          value={userDetails.message}
                          onChange={(e) =>
                            handleChange("message", e.target.value)
                          }
                        ></textarea>
                        <button
                          type="submit"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          className="flex items-center uppercase gap-2 px-5 py-3 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="lg:w-[38%] 2xl:w-[38%] xl:w-[38%] w-full">
                        <div className="lg:flex hidden justify-center items-end mr-0 relative">
                          <img
                            className="object-contain"
                            style={{
                              height: 450,
                              width: 400,
                            }}
                            src={mode1}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal> */}
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: "100vw" }} // Start from the right off-screen
              animate={{ x: 0 }} // Animate to the default position
              transition={{ type: "spring", duration: 2 }} // Transition configuration
            >
              <div className="">
                <img className="w-full rounded-lg " src={bannerImg} alt="" />
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Banner;
