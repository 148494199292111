import ApiCalls from "../Action/ApiCalls/Api";

const makeApiCall = async (method, url, useToken, payloadType, payload) => {
  
  const makeApiCall = ApiCalls.ApiCallHOF(
    method,
    url,
    useToken,
    payloadType,
    payload
  );
  return await makeApiCall();
};

export default makeApiCall;
