import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login/Login";
import Register from "../pages/Login/Register";
import TeamsCms from "../pages/CMS/TeamsCms";
// import Demo from "../pages/Demo";
import My_Article from "../pages/CMS/My_Article";
import Welcome from "../components/Welcome";

import Profile from "../pages/Profile/Profile";
import Requirement from "../pages/Requirement/Requirement";
import Home from "../pages/Home/Home";
import Hiring from "../pages/Hiring/StartHiring";
import Landing_Page1 from "../pages/LandingWizard/Landing_page1";

import Freelancer from "../pages/Freelancer/Freelancer";
import AllVisitors from "../pages/CMS/AllVisitors";
import SlugListing from "../pages/CMS/SlugListing";
import SubscriptionPayment from "../pages/Payment/SubscriptionPayment";
import HealthTech from "../pages/Home/HealthTech";
import HealthTechHiring from "../pages/Hiring/HealthTechHiring";
import Connect from "../pages/Hiring/Connect";
import { ROUTES } from "./RouterConfig";

const Router = () => {
  const RouteWithRole = ({ Element }) => {
    return (
      <>
        <Element />
      </>
    );
  };

  return (
    <div>
      <Routes>
        <Route
          exact
          path={ROUTES.Login}
          element={<RouteWithRole Element={Login} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Register}
          element={<RouteWithRole Element={Register} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Home}
          element={<RouteWithRole Element={Home} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Hiring}
          element={<RouteWithRole Element={Hiring} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HealthTechHiring}
          element={<RouteWithRole Element={HealthTechHiring} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Connect}
          element={<RouteWithRole Element={Connect} />}
        ></Route>
        <Route
          exact
          path={`${ROUTES.TeamsCms}`}
          element={<RouteWithRole Element={TeamsCms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Page}
          element={<RouteWithRole Element={TeamsCms} />}
        ></Route>
        <Route
          exact
          path={ROUTES.My_Article}
          element={<RouteWithRole Element={My_Article} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Profile}
          element={<RouteWithRole Element={Profile} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Requirement}
          element={<RouteWithRole Element={Requirement} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Freelancer}
          element={<RouteWithRole Element={Freelancer} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Visitors}
          element={<RouteWithRole Element={AllVisitors} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Slugs}
          element={<RouteWithRole Element={SlugListing} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Slug}
          element={<RouteWithRole Element={My_Article} />}
        ></Route>
        <Route
          exact
          path={ROUTES.SubscriptionPayment}
          element={<RouteWithRole Element={SubscriptionPayment} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Welcome}
          element={<RouteWithRole Element={Welcome} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Landing_Page1}
          element={<RouteWithRole Element={Landing_Page1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.HealthTech}
          element={<RouteWithRole Element={HealthTech} />}
        ></Route>
      </Routes>
    </div>
  );
};

export default Router;
