import makeApiCall from "../MakeApiCall";

const getVistors = async () => {
  return makeApiCall("GET", `/api/auth/all/visitors`, true, "", null);
};
const deleteVistor = async (deletedRecord) => {
  return makeApiCall(
    "DELETE",
    `/api/auth/visitors/${deletedRecord?.id}`,
    true,
    "",
    deletedRecord
  );
};

const visitorServices = {
  getVistors,
  deleteVistor,
};

export default visitorServices;
