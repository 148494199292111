import React from "react";

const Build = ({ name, img, checked, onClick }) => {
  return (
    <div className=" flex justify-center items-center flex-wrap border h-full text-center rounded-xl relative border-[#eee] px-3 py-3">
      <input
        type="checkbox"
        checked={checked}
        className="absolute top-1 right-2 h-4 w-4"
        onClick={onClick}
      />
      <img
        className="object-contain w-16 h-12 mb-2 mx-auto  cursor-pointer"
        src={img}
        alt=""
      />
      {/* <h2 className="text-[13px] w-full font-medium text-[#626262] flex-wrap break-all"> */}
      <h2 className="text-[13px] w-full font-medium text-[#626262] whitespace-normal break-words">
        {name}
      </h2>
    </div>
  );
};

export default Build;
