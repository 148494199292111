import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Image from "../assets/images/faq.png";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const data = [
  {
    question: "How can I start?",
    content:
      "Sign an NDA with us and then schedule a call to discuss your requirement. Once you submit your requirement you will be connected with a dedicated project manager who will assist you with 2-Weeks Trail for execution and delivery of your request if you wish to continue make the payment as per the SoW.",
  },
  {
    question: "What is the standard practice of communication?",
    content:
      "We are flexible with communication platforms and we use multiple channels like slack, google chats, Teams and Skype to communicate.",
  },
  {
    question: "How fast the teams work?",
    content: `We pride ourselves on our agility and speed, adhering closely to the Agile methodology. Upon receiving your requirements, we'll kickstart Agile development, conducting regular SCRUM meetings to ensure efficient progress and alignment with your needs.`,
  },
  {
    question: "What if I cancel within 2 weeks?",
    content:
      "We will try our best not to let that happen but still if you wish to opt out you will not be charged for the number of days.",
  },
];
const Faq = () => {
  return (
    <section className=" py-8 text-center" id="Faq">
      <div className="text-center">
        <h2 className="text-4xl font-bold mt-2 sm:mt-4 mb-2 sm:mb-2 md:mb-4 lg:mb-4 text-gray-600 fonts-limelight">
          FAQ'S
        </h2>
        <p className="text-lg mt-2 sm:mt-4 mb-2 sm:mb-2 md:mb-4 lg:mb-4 text-gray-600 fonts-limelight">
          Frequently asked question list for your ease
        </p>
      </div>

      {/* <h2 className="text-2xl text-white mb-5 container  tracking-wide">{data.description}</h2> */}
      <div className="container flex flex-row justify-center flex-wrap">
        <div className="lg:w-7/12 md:w-7/12 sm:w-7/12 w-full lg:p-5 p-1">
          <Accordion allowZeroExpanded className="border-none">
            {data.map((items, i) => (
              <AccordionItem key={i} className="mb-3 shadow-sm rounded-lg">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3 className=" text-md font-semibold text-sofyrusPrimary px-6 -mt-6">
                      {" "}
                      {items.question}
                    </h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="text-left  text-md font-medium text-sofyrusPrimary px-6 ">
                    {items.content}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="lg:w-5/12 md:w-5/12 sm:w-5/12 w-full p-5">
          <img
            src={Image}
            className="w-full"
            alt="healthcare software development company"
          />
        </div>
      </div>
    </section>
  );
};

export default Faq;
