import React, { useState, useEffect } from "react";
import CheckoutForm from "../../Services/Payment/CheckoutForm";
// import "./App.css";

const SubscriptionPayment = () => (
  <>
    <CheckoutForm />
  </>
);

export default SubscriptionPayment;
