import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import LogoutIcon from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Logo from "../../assets/images/sofyrus.jpg";

// import LoadingButton from '@mui/lab/LoadingButton';
// import {} from '@mui/material/'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
// import { ContentState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RequirementList from "../../components/RequirementList";
import {
  BsChat,
  BsChatFill,
  BsPen,
  BsTrash,
  BsTrash2,
  BsTrashFill,
} from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { IoAddOutline } from "react-icons/io5";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import inviteMemberService from "../../Services/Requirement/inviteMemberService";
import getInviteMemberService from "../../Services/Requirement/getInviteMemberService";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import taskServices from "../../Services/Requirement/taskServices";
import addRequirementService from "../../Services/Requirement/addRequirementService";
import { ROUTES } from "../../routes/RouterConfig";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Requirement = () => {
  const [value, setValue] = useState(0);
  const [myStatus, setMyStatus] = React.useState("start");
  const navigate = useNavigate();

  const handleChangeStatus = async (event, statusId) => {
    ;
    try {
      const payload = {
        statusId: statusId,
        Status: event.target.value,
      };
      const response = await taskServices.updateTaskStatus(payload);
      const { status, data } = response;
      if (status === 200) {
        toast.success("User task updated successfully.", {
          position: "top-right",
          autoClose: 2000,
        });
        userTasks();
      }
      ;
      // console.log("stsd", statusId);

      // setMyStatus(event.target.value);
    } catch (error) {
      console.log("err", error);
    }
  };
  // const [loading, setLoading] = React.useState(true);
  // function handleClick() {
  //   setLoading(true);
  // }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeValue = (key, value) => {
    setInviteMember({
      ...inviteMember,
      [key]: value,
    });
  };
  const [open, setOpen] = React.useState(false);
  const [openTask, setOpenTask] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRequirementOpen = () => setOpen(true);
  const handleRequirementClose = () => setOpen(false);
  const handleTaskOpen = () => setOpenTask(true);
  const handleTaskClose = () => setOpenTask(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const [inviteMember, setInviteMember] = useState({
    fullName: "",
    email: "",
  });
  const [task, setTask] = useState({
    title: "",
    description: "",
    assignedTo: "",
    assignedBy: "Testing purpose",
    comments: [{ comment: "" }],
    label: "testing",
  });
  const [tasks, setTasks] = useState([]);
  const [requirement, setRequirement] = useState(null);
  const [modifiedRecord, setModifiedRecord] = useState(null);
  const [myRequirement, setMyRequiremnt] = useState([]);
  const [myTeamMember, setMyteamMember] = useState([]);

  // handle logout
  const handleLogout = () => {
    localStorage.removeItem("authDetails");
    // toast.success("User Logout successfully.", {
    //   position: "bottom-left",
    //   autoClose: 2000,
    // });
    navigate(ROUTES.Login);
  };

  const onTaskHandler = (key, value) => {
    setTask((preState) => ({ ...preState, [key]: value }));
  };

  const changeDir = (dir) => {
    navigate(dir);
  };

  const getMemberDetails = async () => {
    try {
      const response = await getInviteMemberService.InviteMember();
      console.log("res", response);
      setMemberDetails(response.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const userTasks = async () => {
    try {
      const response = await taskServices.getUserTask();
      const { status, data } = response;
      if (status === 200 && data && Array.isArray(data)) {
        setTasks(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("all task data", tasks);
  useEffect(() => {
    getMemberDetails();
    userTasks();
    getRequirement();
    getTeamMember();
  }, []);

  // console.log("details", memberDetails);

  const handleSubmit = async () => {
    try {
      const response = await inviteMemberService.InviteMember(inviteMember);
      console.log("res", response);
      const { data, status } = response;
      if (status === 201 && data) {
        toast.success("Invite member added successfully.", {
          position: "bottom-right",
          autoClose: 2000,
        });
        setInviteMember({
          fullName: "",
          email: "",
        });
        handleClose();
        getMemberDetails();
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const deleteMember = async (deletedRecord) => {
    const response = await inviteMemberService.DeleteMember(deletedRecord);
    const { status, data } = response;
    if (status === 200 && data) {
      toast.success("Invited member deleted successfully.", {
        position: "bottom-right",
        autoClose: 2000,
      });
      getMemberDetails();
    }
  };

  const editMember = async (mData) => {
    const response = await inviteMemberService.EditMember(mData);
    const { status, data } = response;
    if (status === 200 && data) {
      toast.success("Invited member updated successfully.", {
        position: "bottom-right",
        autoClose: 2000,
      });
      setInviteMember({
        fullName: "",
        email: "",
      });
      handleClose();
      getMemberDetails();
    }
  };

  const updateHandler = (uData) => {
    handleOpen();
    setInviteMember(uData);
  };

  const deleteTask = async (dRecord) => {
    try {
      const response = await taskServices.deleteUserTask(dRecord);
      const { status, data } = response;
      if (status === 200 && data) {
        toast.success("Task deleted successfully.", {
          position: "bottom-right",
          autoClose: 2000,
        });
        userTasks();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty()); // here need to uncomment

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent();
    const text = currentContent.getPlainText("\u0001");
    setTask((preState) => ({ ...preState, comments: [{ comment: text }] }));
    console.log("Text value:", text);
  };

  const resetHandler = () => {
    handleTaskClose();
    setTask({
      title: "",
      description: "",
      assignedTo: "",
      comments: [""],
    });
  };
  const createTask = async () => {
    ;
    try {
      const response = await taskServices.createUserTask(task);
      const { status, data } = response;
      ;
      if (status === 201 && data) {
        toast.success("Task created successfully.", {
          position: "bottom-right",
          autoClose: 2000,
        });
        resetHandler();
        userTasks();
      }
    } catch (error) {
      console.log(error);
      ;
    }
  };

  const editTask = async (updateData) => {
    const response = await taskServices.updateUserTask(updateData);
    const { status, data } = response;
    if (status === 200 && data) {
      toast.success("Task updated successfully.", {
        position: "bottom-right",
        autoClose: 2000,
      });
      resetHandler();
      userTasks();
    }
  };

  const updateModalHandling = (uData) => {
    handleTaskOpen();
    setTask(uData);
  };

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [editorState, setEditorState] = useState(() => {
  //   // Initialize editor state with pre-filled content
  //   const contentState = ContentState.createFromText(requirement.text);
  //   return EditorState.createWithContent(contentState);
  // });

  const onRequirementEditorStateChange = (editorState) => {
    ;
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent();
    const text = currentContent.getPlainText("\u0001");
    setRequirement((preState) => ({ ...preState, text }));
    // setTask((preState) => ({ ...preState, comments: [text] }));
    console.log("Text value:", text);
  };

  // handle requirement submit
  const handleRequireSubmit = async () => {
    try {
      const response = await addRequirementService.addRequirement(requirement);
      console.log("response", response);

      const { data, status } = response;
      if (status === 201 && data) {
        toast.success("requirement added successfully.", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
      getRequirement();
      setRequirement("");
      handleRequirementClose();
    } catch (error) {
      console.log("error", error);
    }
  };

  // get requiremnet
  const getRequirement = async () => {
    try {
      const response = await addRequirementService.getRequirement();
      console.log("myreq", response);
      setMyRequiremnt(response.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const delRequirement = async (deletedRecord) => {
    try {
      const response = await addRequirementService.deleteRequirement(
        deletedRecord
      );
      const { status, data } = response;
      if (status === 200 && data) {
        toast.success("Requirement deleted successfully.", {
          position: "bottom-right",
          autoClose: 2000,
        });
        getRequirement();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editRequirement = async () => {
    try {
      const response = await addRequirementService.updateRequirement(
        requirement
      );
      const { status, data } = response;
      if (status === 200 && data) {
        toast.success("Requirement updated successfully.", {
          position: "bottom-right",
          autoClose: 2000,
        });
        getRequirement();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const modalHandling = (uData) => {
    const data = stateToHTML(convertFromRaw({ text: uData?.text }));
    ;
    console.log("editirdfdfState", data);
    setRequirement(uData);

    handleRequirementOpen();
  };

  // get all team member
  const getTeamMember = async () => {
    ;
    try {
      const response = await taskServices.getAllTeamMember();
      setMyteamMember(response.data);
      ;
    } catch (error) {
      console.log(error);
    }
  };

  // handleChangeMember
  const handleChangeMember = (e) => {
    ;
    setTask((pTask) => ({ ...pTask, assignedTo: e.target.value }));
    /* setMyteamMember(e.target.value); */
  };

  return (
    <div className="Login">
      {/* <Header /> */}

      <div
        style={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}
        className="shadow-md"
      >
        <div>
          <Box
            // component={RouterLink}
            to="/"
            sx={{
              px: 2.5,
              py: 5,
              display: "flex",
              alignItems: "center",
              gap: 2,
              borderRight: 1,
              borderColor: "#eee",
            }}
          >
            {/* <Logo /> */}
            <img src={Logo} alt="sofyrus image" className="w-[25%] h-[25%]" />
            <Typography
              fontWeight={500}
              variant="h6"
              color={"#333"}
              className="font-sans"
            >
              Teams Sofyrus
            </Typography>
          </Box>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            className="py-3 w-72 h-screen text-left relative "
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              // backgroundColor: "#edfdf3",
            }}
          >
            <Tab
              sx={{ fontSize: "14px" }}
              icon={<AssignmentIcon />}
              iconPosition="start"
              label="Your Requirements"
            />
            <Tab
              icon={<Diversity3Icon />}
              iconPosition="start"
              label="My Teams"
              {...a11yProps(1)}
              // sx={{ backgroundColor: value === 1 ? "#D1BB9E" : "" }}
            />
            <Tab
              icon={<GroupAddIcon />}
              iconPosition="start"
              label="Add Team Member"
              {...a11yProps(2)}
            />
            <Tab
              icon={<CardMembershipIcon />}
              iconPosition="start"
              label="Subscription"
              {...a11yProps(2)}
            />
            <div className="flex  absolute bottom-5 left-5 w-full mx-2">
              <button
                className="px-3 py-2 flex gap-3 text-base justify-center w-[180px] font-semibold font-sans border border-transparent rounded-lg bg-slate-400 hover:text-white"
                onClick={handleLogout}
              >
                <LogoutIcon size={"1.5rem"} />
                LOG OUT
              </button>
            </div>
          </Tabs>
        </div>

        <TabPanel value={value} index={0}>
          <section className="bg-white">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {/* <h2 className="text-xl font-bold leading-tight text-black sm:text-2xl">
                    What are you looking for?
                  </h2> */}
              <div className="py-3 px-3">
                <button
                  type="submit"
                  onClick={handleRequirementOpen}
                  className="flex items-center  uppercase gap-2  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                >
                  <IoAddOutline size={"1.5rem"} />
                  Add Requirement
                </button>
                <Modal
                  open={open}
                  onClose={handleRequirementClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className="modal-ui flex flex-col  justify-center">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      What are you looking for?
                    </Typography>
                    <div className="flex flex-col mt-3 gap-3 items-center">
                      {/* <TextField
                            className="w-full"
                            id="outlined-basic"
                            label="add your title"
                            variant="outlined"
                            color="success"
                            // value={userDetails.companyName}
                            // onChange={(e) =>
                            //   handleChange("companyName", e.target.value)
                            // }
                          /> */}
                      {/* <textarea
                        class="form-control "
                        id="message-text"
                        placeholder="Message"
                        // value={userDetails.message}
                        // onChange={(e) =>
                        //   handleChange("message", e.target.value)
                        // }
                      ></textarea> */}
                      <div className="xl:w-full xl:max-w-lg 2xl:max-w-xl xl:mx-auto">
                        <div className="space-y-5">
                          <div>
                            <label
                              htmlhtmlFor=""
                              className="text-base font-medium text-gray-900 mb-2"
                            >
                              {" "}
                              Describes your Requirements here{" "}
                            </label>
                            {/* <Editor
                                  editorState={editorState}
                                  wrapperClassName="demo-wrapper border"
                                  editorClassName="demo-editor h-40"
                                  onEditorStateChange={
                                    onRequirementEditorStateChange
                                  }
                                /> */}
                            <Editor
                              editorState={editorState}
                              wrapperClassName="demo-wrapper border"
                              editorClassName="demo-editor h-40"
                              onEditorStateChange={
                                onRequirementEditorStateChange
                              }
                            />
                            {/* <Editor
                              editorState={editorState}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              onEditorStateChange={
                                onRequirementEditorStateChange
                              }
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                              }}
                            /> */}
                          </div>

                          {/* <div> */}
                          <button
                            type="submit"
                            onClick={() => {
                              requirement && requirement?.id
                                ? editRequirement(requirement)
                                : handleRequireSubmit();
                            }}
                            className="inline-flex uppercase items-center justify-center w-full px-4 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                          >
                            {requirement && requirement?.id
                              ? "Update"
                              : "Submit"}
                          </button>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* <div className="mt-2 justify-center py-2 text-center">
                      <h2 className="font-semibold">Full Stack</h2>
                    </div> */}
                {/* {Array.isArray(myRequirement) &&
                      myRequirement?.map((requireMent, i) => (
                        <>
                          <RequirementList
                            requirement={requireMent}
                            setModifiedRecord={setModifiedRecord}
                          />
                        </>
                      ))} */}

                {Array.isArray(myRequirement) &&
                  myRequirement?.map((requirement, i) => (
                    <div
                      className="flex justify-between mt-3 items-center gap-3 bg-[#fff] shadow-md rounded-lg py-3 px-3 my-2 w-full"
                      key={`${requirement}/${i}`}
                    >
                      <div className="flex items-center gap-3">
                        <h2 className="text-lg font-medium mb-1">
                          {requirement?.text}
                        </h2>
                      </div>
                      <div className="flex gap-3">
                        <a
                          href="#"
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          onClick={() => modalHandling(requirement)}
                        >
                          <MdEdit size={"1.5rem"} />
                        </a>
                        <a
                          href="#"
                          className="font-medium text-red-600 dark:text-red-500 hover:underline"
                          onClick={() => delRequirement(requirement)}
                        >
                          <BsTrashFill size={"1.3rem"} />
                        </a>
                      </div>
                    </div>
                  ))}

                {/* {alert('hh',editorState)} */}
              </div>
              <div className="py-3 px-3">
                <button
                  type="submit"
                  onClick={handleTaskOpen}
                  className="flex items-center  uppercase gap-2  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                >
                  <IoAddOutline size={"1.5rem"} />
                  Add Task
                </button>
                <Modal
                  open={openTask}
                  onClose={handleTaskClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className="modal-ui flex flex-col  justify-center">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Add Task
                    </Typography>
                    <div className="flex flex-col mt-3 gap-3 items-center">
                      <TextField
                        className="w-full"
                        size="small"
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        color="success"
                        value={task?.title}
                        onChange={(e) => onTaskHandler("title", e.target.value)}
                      />
                      <TextField
                        className="w-full"
                        id="outlined-basic"
                        size="small"
                        label="Description"
                        variant="outlined"
                        color="success"
                        value={task?.description}
                        onChange={(e) =>
                          onTaskHandler("description", e.target.value)
                        }
                      />
                      <FormControl sx={{ m: 1, minWidth: 550 }} size="small">
                        <InputLabel id="demo-simple-select-label">
                          Assigned to
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={task.assignedTo}
                          label="Start"
                          // className="font-sans text-sm"
                          onChange={(e) => handleChangeMember(e)}
                        >
                          {myTeamMember &&
                            Array.isArray(myTeamMember) &&
                            myTeamMember.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.fullName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      {/* <TextField
                        className="w-full"
                        size="small"
                        id="outlined-basic"
                        label="Assigned To"
                        variant="outlined"
                        color="success"
                        value={task.assignedTo}
                        onChange={(e) =>
                          onTaskHandler("assignedTo", e.target.value)
                        }
                      /> */}

                      {/* <textarea
                        class="form-control "
                        id="message-text"
                        placeholder="Message"
                        // value={userDetails.message}
                        // onChange={(e) =>
                        //   handleChange("message", e.target.value)
                        // }
                          ></textarea> */}
                      <div className="xl:w-full xl:max-w-lg 2xl:max-w-xl xl:mx-auto">
                        <div className="space-y-5">
                          <div>
                            <label
                              htmlhtmlFor=""
                              className="text-base font-medium text-gray-900 mb-2"
                            >
                              {" "}
                              Comments
                            </label>
                            <Editor
                              editorState={editorState}
                              wrapperClassName="demo-wrapper border"
                              editorClassName="demo-editor h-40"
                              onEditorStateChange={onEditorStateChange}
                            />
                          </div>

                          <div className="flex justify-between gap-3">
                            <button
                              type="submit"
                              className="inline-flex h-10 uppercase items-center justify-center w-full px-4 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                              onClick={() =>
                                Object.keys(task)?.length && task?.id
                                  ? editTask(task)
                                  : createTask()
                              }
                            >
                              {Object.keys(task)?.length && task?.id
                                ? "Update"
                                : "Submit"}
                            </button>
                            <button
                              type="submit"
                              className="inline-flex h-10 uppercase items-center justify-center w-full px-4 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-slate-400 focus:outline-none hover:opacity-80 focus:opacity-80"
                              onClick={resetHandler}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <div>
                  {tasks && tasks?.length ? (
                    Array.isArray(tasks) &&
                    tasks?.map((task, i) => (
                      // <div
                      //   className="relative px-4 pb-10 pt-6 sm:pb-16 lg:pb-24 bg-gray-50 sm:px-6 lg:px-8"
                      //   key={`${task}/${i}`}
                      // >
                      <div className="bg-white rounded-lg py-2 px-3 my-2 shadow-md">
                        <h2 className="text-base font-sans font-medium mb-1">
                          {task?.title}
                        </h2>
                        <p className="text-sm font-normal text-[#858585]">
                          {task?.description}
                        </p>
                        <div className="flex gap-3 items-center justify-between py-2 mt-2">
                          <div className="flex items-center gap-3">
                            <Avatar sx={{ width: 32, height: 32 }}>
                              {task?.assignedTo?.charAt(0).toUpperCase()}
                            </Avatar>
                            {/* <div className="bg-[#4ade80] text-xs px-2 py-1 rounded-md text-white">
                          // {task?.Status}
                        </div> */}
                            <FormControl
                              sx={{ m: 1, minWidth: 90 }}
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={task?.Status}
                                label="Start"
                                // className="font-sans text-sm"
                                onChange={(e) =>
                                  handleChangeStatus(e, task?.id)
                                }
                              >
                                <MenuItem value="inprogress">
                                  Inprogress
                                </MenuItem>
                                <MenuItem value="resolved">Resolved</MenuItem>
                                <MenuItem value="start">Start</MenuItem>
                              </Select>
                            </FormControl>
                            <div className="flex gap-2 items-center">
                              <BsChatFill color="gray" />{" "}
                              <h2 className="text-sm  font-sans font-medium">
                                {task.comments?.length > 1
                                  ? `${task.comments?.length} comments`
                                  : `${task.comments?.length} comment`}
                              </h2>
                            </div>
                          </div>
                          {/* <div className="bg-[#4ade80] text-sm px-3 py-1.5 rounded-xl text-white">
                    In Progress
                  </div> */}
                          <div className="flex justify-between gap-2">
                            <a
                              href="#"
                              className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                              onClick={() => updateModalHandling(task)}
                            >
                              <MdEdit size={"1.5rem"} />
                            </a>
                            <a
                              href="#"
                              className="font-medium text-red-600 dark:text-red-500 hover:underline"
                              onClick={() => deleteTask(task)}
                            >
                              <BsTrashFill size={"1.3rem"} />
                            </a>
                          </div>
                        </div>
                      </div>
                      // </div>
                    ))
                  ) : (
                    <>
                      <div className="flex flex-col items-center justify-center h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-20 w-20 text-gray-400 mb-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p className="text-lg text-gray-600 mb-2">
                          No Task found!!
                        </p>
                        <p className="text-sm text-gray-400">
                          Please create a task to showing on this page!
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* 
                  <RequirementList />
                  <RequirementList />
                  <RequirementList /> */}
              {/* <form action="#" method="POST" className="mt-3">
                    <div className="space-y-5">
                      <div>
                        <label
                          htmlhtmlFor=""
                          className="text-base font-medium text-gray-900 mb-2"
                        >
                          {" "}
                          Describes your Requirements{" "}
                        </label>
                        <Editor
                          // editorState={editorState}
                          wrapperClassName="demo-wrapper border"
                          editorClassName="demo-editor h-48"
                          // onEditorStateChange={onEditorStateChange}
                        />
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="inline-flex uppercase items-center justify-center w-full px-4 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form> */}
            </div>

            {/* <div className="flex justify-end">
                <button
                  type="submit"
                  onClick={handleLogout}
                  className="bg-[#4ade80]  w-40 h-[43px]  px-3 mt-3 rounded-lg text-sm font-medium text-white transition-all duration-200 border border-transparent focus:outline-none hover:opacity-80 focus:opacity-80"
                >
                 
                  LOG OUT
                </button>
              </div> */}
          </section>
          {/* <div className="bg-white rounded-lg py-2 px-3 my-2 shadow-md flex justify-between ">
            <div>
              <h2 className="text-sm font-medium">
                Add some task in list solved.
              </h2>
              <div className="flex gap-3 items-center justify-between py-2">
                <div className="flex items-center gap-3">
                  <Avatar sx={{ width: 20, height: 20 }}>
                    <span className="text-[10px]">H</span>
                  </Avatar>
                  <div className="bg-orange-400 text-[10px] px-2 py-1 rounded-md text-white">
                    Resolved
                  </div>
                  <div className="flex gap-2 items-center">
                    <BsChatFill />{" "}
                    <h2 className="text-[10px] font-medium">5 Comments</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#4ade80] text-[10px] px-3 py-1.5 rounded-xl text-white h-6 mt-2">
              Completed
            </div>
          </div> */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="relative  mx-auto  py-5 px-5 w-full bg-gray-50">
            <div className="flex justify-end">
              <button
                type="submit"
                onClick={handleOpen}
                className="flex items-center  uppercase gap-2  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
              >
                <IoAddOutline size={"1.5rem"} />
                Invite User
              </button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="modal-ui">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Team Member
                  </Typography>
                  <div className=" flex gap-5 items-center">
                    <TextField
                      className="w-full my-3"
                      id="outlined-basic"
                      label="Full Name"
                      variant="outlined"
                      color="success"
                      value={inviteMember.fullName}
                      onChange={(e) =>
                        handleChangeValue("fullName", e.target.value)
                      }
                    />
                    <TextField
                      className="w-full my-3"
                      id="outlined-basic"
                      label="Email Address"
                      variant="outlined"
                      color="success"
                      value={inviteMember.email}
                      onChange={(e) =>
                        handleChangeValue("email", e.target.value)
                      }
                    />
                    <button
                      type="submit"
                      onClick={() =>
                        inviteMember && inviteMember?.id
                          ? editMember(inviteMember)
                          : handleSubmit()
                      }
                      className="flex items-center  uppercase gap-2  px-5 py-3 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                    >
                      {inviteMember && inviteMember.id ? "UPDATE" : "SUBMIT"}
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs font-sans text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Position
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(memberDetails) &&
                  memberDetails?.map((member, i) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      key={`${member}/${i}`}
                    >
                      <th
                        scope="row"
                        className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
                      >
                        <img
                          className="w-10 h-10 rounded-full"
                          src="https://mui.com/static/images/avatar/2.jpg"
                          alt="Jese image"
                        />
                        <div className="ps-3">
                          <div className="text-base text-black font-semibold">
                            {/* Neil Sims */} {member.fullName}
                          </div>
                          <div className="font-normal text-gray-500">
                            {/* neil.sims@flowbite.com */} {member.email}
                          </div>
                        </div>
                      </th>
                      <td className="px-6 py-4">React Developer</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center">
                          <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>{" "}
                          Online
                        </div>
                      </td>
                      <td className="px-6 py-4 flex gap-2 items-center">
                        <a
                          href="#"
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          onClick={() => updateHandler(member)}
                        >
                          <MdEdit size={"1.5rem"} />
                        </a>
                        <a
                          href="#"
                          className="font-medium text-red-600 dark:text-red-500 hover:underline"
                          onClick={() => deleteMember(member)}
                        >
                          <BsTrashFill size={"1.3rem"} />
                        </a>
                      </td>
                    </tr>
                  ))}
                {/* Add remaining rows similarly */}
              </tbody>
            </table>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="py-4 px-5 w-full bg-gray-50">
            <div className="  text-right mb-4">
              {/* <button
                type="submit"
                onClick={handleOpen}
                className="flex items-center  uppercase gap-2  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
              >
                <IoAddOutline size={"1.5rem"} />
                Invite User
              </button> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div className="modal-ui">
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add Team Member
                  </Typography>
                  <div className=" flex gap-5 items-center">
                    <TextField
                      className="w-full my-3"
                      id="outlined-basic"
                      label="Full Name"
                      variant="outlined"
                      color="success"
                      value={inviteMember.fullName}
                      onChange={(e) =>
                        handleChangeValue("fullName", e.target.value)
                      }
                    />
                    <TextField
                      className="w-full my-3"
                      id="outlined-basic"
                      label="Email Address"
                      variant="outlined"
                      color="success"
                      value={inviteMember.email}
                      onChange={(e) =>
                        handleChangeValue("email", e.target.value)
                      }
                    />
                    <button
                      type="submit"
                      onClick={() =>
                        inviteMember && inviteMember?.id
                          ? editMember(inviteMember)
                          : handleSubmit()
                      }
                      className="flex items-center  uppercase gap-2  px-5 py-3 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                    >
                      {inviteMember && inviteMember.id ? "UPDATE" : "SUBMIT"}
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="flex w-full gap-3">
              {/* {memberDetails?.map((item) => (
                <div
                  key={item.id}
                  className="bg-[#fff] shadow-md rounded-lg py-3 px-3 my-2 w-[33%]"
                >
                  <div className="flex items-center gap-3">
                    <Avatar
                      sx={{ width: 48, height: 48, bgcolor: deepPurple[500] }}
                    >
                      {item.fullName.charAt(0)}{" "}
                      {/* Displaying the first character of the full name */}
              {/* </Avatar>
                    <div>
                      <h2 className="text-base font-semibold mb-1">
                        {item.fullName}
                      </h2>
                      <h2 className="text-sm font-medium text-[#666]">
                        {item.email}
                      </h2>
                    </div>
                  </div>
                </div> */}
              {/* ))} */}

              {Array.isArray(memberDetails) &&
                memberDetails?.map((member, i) => (
                  <div
                    className="flex justify-between items-center gap-3 bg-[#fff] shadow-md rounded-lg py-3 px-3 my-2 w-[33%]"
                    key={`${member}/${i}`}
                  >
                    <div className="flex justify-between items-center gap-3">
                      <Avatar
                        sx={{ width: 48, height: 48, bgcolor: deepPurple[500] }}
                      >
                        {member?.fullName?.charAt(0)}
                      </Avatar>
                      <div>
                        <h2 className="text-base font-semibold mb-1">
                          {member?.fullName}
                        </h2>
                        <h2 className="text-sm font-medium text-[#666]">
                          {member?.email}
                        </h2>
                      </div>
                    </div>
                    <div className="flex gap-3">
                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => updateHandler(member)}
                      >
                        <MdEdit size={"1.5rem"} />
                      </a>
                      <a
                        href="#"
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                        onClick={() => deleteMember(member)}
                      >
                        <BsTrashFill size={"1.3rem"} />
                      </a>
                    </div>
                  </div>
                ))}

              {/*  <div className="bg-[#fff] shadow-md rounded-lg py-3 px-3 my-2 w-[33%]">
                <div className="flex items-center gap-3 ">
                  <Avatar
                    sx={{ width: 48, height: 48, bgcolor: deepOrange[500] }}
                  >
                    A
                  </Avatar>
                  <div>
                    <h2 className="text-base font-semibold mb-1">
                      Areeb Azhar
                    </h2>
                    <h2 className="text-sm font-medium text-[#666]">
                      areeb@sofyrus.com
                    </h2>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="py-4 px-5 w-full bg-gray-50">
            <div className="py-4 mx-auto  max-w-7xl">
              <h2 className="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-4xl">
                Subscribed Plans
              </h2>
            </div>
            <div className="flex flex-wrap w-full gap-3">
              <div className="bg-[#fff] shadow-md rounded-2xl py-4 px-4 my-2 w-[30%]">
                <div className="flex items-center justify-between gap-3 mb-5">
                  <div>
                    <h2 className="text-xl font-semibold text-[#000] mb-2">
                      Monthly Plan
                    </h2>
                    <p className="text-sm text-[#666]">
                      Pause or Cancel Anytime
                    </p>
                  </div>

                  <h2 className="text-4xl font-semibold mb-1">$6000</h2>
                </div>
                <button
                  type="submit"
                  className="w-full  uppercase gap-2  px-3 py-3 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                >
                  Subscribed
                </button>
              </div>
              {/* <div className="bg-[#fff] shadow-md rounded-2xl py-4 px-4 my-2 w-[30%]">
                <div className="flex items-center justify-between gap-3 mb-5">
                  <div>
                    <h2 className="text-xl font-semibold text-[#000] mb-2">
                      Monthly Plan
                    </h2>
                    <p className="text-sm text-[#666]">Expired</p>
                  </div>

                  <h2 className="text-4xl font-semibold mb-1">$3000</h2>
                </div>
                <button
                  type="submit"
                  className="w-full  uppercase gap-2  px-3 py-3 text-sm font-medium text-[#666] transition-all duration-200 border border-transparent rounded-lg bg-[#ddd] focus:outline-none hover:opacity-80 focus:opacity-80"
                >
                  Expired
                </button>
              </div> */}
            </div>
          </div>
        </TabPanel>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default Requirement;
