import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { MdEdit } from "react-icons/md";
import { deepOrange, deepPurple } from "@mui/material/colors";
import blogServices from "../../Services/Requirement/blogServices";

const SlugListing = () => {
  const [blogs, setBlogs] = useState([]);
  const allBlogs = async () => {
    try {
      const response = await blogServices.getBlogs();
      console.log("res", response);
      setBlogs(response.data);
    } catch (error) {
      console.log("err", error);
    }
  };
  useEffect(() => allBlogs(), []);

  return (
    <div className="flex flex-wrap gap-1">
      {Array.isArray(blogs) &&
        blogs?.map((blog, i) => (
          <div
            className="flex justify-between items-center gap-3 bg-[#fff] shadow-md rounded-lg py-3 px-3 my-2 w-[33%]"
            key={`${blog}/${i}`}
          >
            <div className="flex justify-between items-center gap-3">
              <Avatar sx={{ width: 48, height: 48, bgcolor: deepPurple[500] }}>
                {blog?.heading?.charAt(0)?.toUpperCase()}
              </Avatar>
              <div>
                <h2 className="text-base font-semibold mb-1">
                  {blog?.heading}
                </h2>
                <h2 className="text-sm font-medium text-[#666]">
                  {blog?.slug}
                </h2>
              </div>
            </div>
            <Link to={`/slugs/${blog?.slug}`} title="" className="flex gap-3">
              {/* <div className="flex gap-3"> */}
              <div className="bg-[#4ade80] text-xs px-2 py-1 rounded-md text-white hover:bg-green-700  cursor-pointer">
                Visit
              </div>
            </Link>
            {/* </div> */}
          </div>
        ))}
    </div>
  );
};

export default SlugListing;
