import makeApiCall from "../MakeApiCall";

const InviteMember = async () => {
  return makeApiCall("GET", `/api/team`, true, "", null);
};

const getInviteMemberService = {
  InviteMember,
};

export default getInviteMemberService;
